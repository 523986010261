import React from 'react'
import ProgressBar from '../ProgressBar'
import PolizaStep from '../SiniestrosSteps/PolizaStep'
import { useWizardSiniestros } from '../../context/WizardSiniestrosProvider'

const WizardSiniestros = ({ children }) => {

  const { step, steps } = useWizardSiniestros()

  return (
    <div>
        <ProgressBar steps={steps} step={step} />
        {children[step - 1]}
    </div>
  )
}

export default WizardSiniestros