import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { IoClose } from "react-icons/io5";
import { BiInfoCircle } from "react-icons/bi";
import disableScroll from "disable-scroll";

export default function ModalEjemplo({
  handleClose,
  toggleScroll,
  titulo,
  texto,
  imagen,
}) {
  toggleScroll != "" && disableScroll.on();
  return (
    <Container>
      <section id="modal-container">
        <a onClick={handleClose}>
          <IoClose />
        </a>
        <div id="imagen-container">
          <img src={imagen} alt="Ilustración de ejemplo"></img>
        </div>
        <div id="texto-container">
          <h4>
            <span>
              <BiInfoCircle></BiInfoCircle>
            </span>
            {titulo}
          </h4>
          <p>{texto}</p>
        </div>
      </section>
    </Container>
  );
}
