import React from "react";
import Container from "./styled";
import Tarjeta from "../../pasos/contrata/tarjeta";

const MetodoPagoForm = ({
  formValues,
  inputsRef,
  handlePaymentMethod,
  metodoDePago,
}) => {
  const { inputValues, handleChange, formErrors } = formValues;

  // Fechas para la tarjeta
  const fecha = new Date();
  const obtenerMesActual = fecha.getMonth() + 1;
  const mesActual =
    obtenerMesActual.toString().length < 2
      ? "0" + obtenerMesActual
      : obtenerMesActual;
  const añoActual = fecha.getFullYear();

  return (
    <Container>
      {/* Tabs */}
      <div id="tabs-container">
        <a
          onClick={() => handlePaymentMethod("credito")}
          className={metodoDePago == "credito" && "tab-activa"}
        >
          <span></span>
          Tarjeta de credito
        </a>
        <a
          onClick={() => handlePaymentMethod("debito")}
          className={metodoDePago == "debito" && "tab-activa"}
        >
          <span></span>
          Débito automático
        </a>
      </div>
      {/* Pago Transferencia Bancaria */}
      {metodoDePago == "debito" && (
        <div className="input-container" id="input-cbu-container">
          <label className="input-label" for="inputCBU">
            CBU
          </label>
          <input
            type="number"
            id="inputCBU"
            name="cbu"
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            value={inputValues.cbu}
            className={formErrors.cbu == null ? "" : "input-error"}
            placeholder="CBU"
            ref={(element) => (inputsRef.current["cbu"] = element)}
          ></input>
          {formErrors.cbu && <span>{formErrors.cbu}</span>}
        </div>
      )}
      {/* Pago Tarjeta Container */}
      {metodoDePago == "credito" && (
        <div id="pago-tarjeta-container">
          <div id="datos-tarjeta-credito">
            {/* Input Número de tarjeta */}
            <div
              className="input-container"
              id="input-numero-tarjeta-container"
            >
              <label className="input-label" for="input-numero-tarjeta">
                Número de tarjeta
              </label>
              <input
                type="number"
                id="input-numero-tarjeta"
                name="numeroTarjeta"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.numeroTarjeta}
                className={
                  formErrors.numeroTarjeta == null ? "" : "input-error"
                }
                placeholder="Número de tarjeta"
                ref={(element) =>
                  (inputsRef.current["numeroTarjeta"] = element)
                }
              ></input>
              {formErrors.numeroTarjeta && (
                <span>{formErrors.numeroTarjeta}</span>
              )}
            </div>
            {/* Input Bnaco Emisor */}
            <div className="input-container" id="input-banco-emisor-container">
              <label className="input-label" for="inputBancoEmisor">
                Banco emisor
              </label>
              <input
                type="text"
                id="inputBancoEmisor"
                name="bancoEmisor"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.bancoEmisor}
                className={formErrors.bancoEmisor == null ? "" : "input-error"}
                placeholder="Banco emisor"
                ref={(element) => (inputsRef.current["bancoEmisor"] = element)}
              ></input>
              {formErrors.bancoEmisor && <span>{formErrors.bancoEmisor}</span>}
            </div>
            {/* Input Vencimiento */}
            <div className="input-container" id="input-vencimiento-container">
              <label className="input-label" for="inputVencimiento">
                Vencimiento
              </label>
              <input
                type="month"
                // De esta manera no deja poner tarjetas vencidas y se updatea solo
                min={añoActual + "-" + mesActual}
                max={añoActual + 8 + "-" + mesActual}
                id="inputVencimiento"
                name="vencimiento"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.vencimiento}
                className={formErrors.vencimiento == null ? "" : "input-error"}
                placeholder="Vencimiento"
                ref={(element) => (inputsRef.current["vencimiento"] = element)}
              ></input>
              {formErrors.vencimiento && <span>{formErrors.vencimiento}</span>}
            </div>
            {/* Input Titular */}
            <div className="input-container" id="input-titular-container">
              <label className="input-label" for="inputTitular">
                Titular de la tarjeta
              </label>
              <input
                type="text"
                id="inputTitular"
                name="titular"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.titular}
                className={formErrors.titular == null ? "" : "input-error"}
                placeholder="Titular de la tarjeta"
                ref={(element) => (inputsRef.current["titular"] = element)}
              ></input>
              {formErrors.titular && <span>{formErrors.titular}</span>}
            </div>
            {/* Input DNI Titular */}
            <div className="input-container" id="input-dni-titular-container">
              <label className="input-label" for="input-DNI-titular">
                DNI del titular
              </label>
              <input
                type="number"
                id="input-DNI-titular"
                name="dniTitular"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.dniTitular}
                className={formErrors.dniTitular == null ? "" : "input-error"}
                placeholder="DNI del titular"
                ref={(element) => (inputsRef.current["dniTitular"] = element)}
              ></input>
              {formErrors.dniTitular && <span>{formErrors.dniTitular}</span>}
            </div>
          </div>
          {/* Tarjeta Credito Container */}
          <div id="tarjeta-credito-container">
            <Tarjeta
              numero={inputValues.numeroTarjeta}
              titular={inputValues.titular}
              vencimiento={inputValues.vencimiento}
              cvc={inputValues.cvc}
              focused={inputValues.focus}
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default MetodoPagoForm;
