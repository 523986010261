import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100% !important;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Pop Up Errores
  #popup-error {
    width: auto;
    max-width: 380px;
    height: auto;
    margin: 0px 0px 40px 40px;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--blanco);
    border-radius: 16px;
    border: 1px solid var(--grisClaro);
    box-shadow: 0px 4px 20px var(--sombraNaranja);
    transform-origin: top center;
    animation-name: animation-in;
    animation-duration: 0.4s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
    z-index: 100;
  }

  // Animación Entrada Popup Erorres
  @keyframes animation-in {
    from {
      transform: translate(0px, 300px);
    }
    to {
      transform: translate(0px, 0px);
    }
  }

  // Pop Up Errores Ícono
  #popup-error span {
    margin: 3px 10px 0px 0px;
    color: var(--naranja100);
    font-size: 17px;
  }

  // Pop Up Errores Texto
  #popup-error p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    color: var(--gris);
    font-size: 13.5px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
  }

  // Max Width Container
  #max-width-container {
    width: 1270px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  // Texto Container
  #formulario-container {
    width: 700px;
    margin: 0px 0px 0px 0px;
    padding: 80px 200px 160px 0px;
    border-right: 1px solid var(--grisClaro);
  }

  // Mapa Container
  #mapa-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    border-top: 1px solid var(--grisClaro);
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 22px;
  }

  // Titulo Mapa Container
  #mapa-container h3 {
    margin: 48px 0px 0px 0px;
  }

  // Texto
  #formulario-container p {
    width: 100%;
    margin: 24px 0px 0px 0px;
    text-align: left;
    font-size: 15px;
    color: var(--gris);
    line-height: 32px;
  }

  // Texto Completa
  #completa {
    margin: 16px 0px 0px 0px;
  }

  // Formulario
  form {
    width: 100%;
    margin: 56px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Datos Personales, CV Container, Mensaje Motivo, Captcha Container, Boton Container
  #datos-personales,
  #mensaje-motivo,
  #cv-container,
  #captcha-container,
  #boton-container {
    width: 100%;
    margin: 0px 0px 48px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Datos Personales Titulo y Mensaje Motivo Titulo
  #datos-personales h4,
  #cv-container h4,
  #mensaje-motivo h4 {
    width: 100%;
    //margin: 0px 0px 32px 0px;
  }
  
  #cv-input-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Input CV
  #cv-input {
    display: none;
  }

  #cv-boton {
    width: auto;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    background-color: var(--blanco) !important;
    color: var(--lila90) !important;
    font-size: 14px;
    font-weight: 700;
    transition: 0.2s all;
    cursor: pointer;
  }

  #cv-boton span {
    margin: 4px 8px 0px 0px;
    font-size: 19px;
    transition: 0.2s all;
  }

  #cv-boton:hover {
    color: var(--lila100) !important;
  }

  #archivo-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  #archivo-boton {
    width: auto;
    text-decoration: none !important;
    color: var(--lila100) !important;
    cursor: pointer;
  }

  #borrar-boton {
    margin: 3px 0px 0px 12px;
    color: var(--rojo);
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s all;
  }

  #borrar-boton:hover {
    color: var(--negro);
  }

  // Botón Container Botón
  #boton-container button {
    padding: 7px 24px 7px 24px;
    border-radius: 8px;
    border: 0px solid;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #boton-container button span {
    font-size: 20px;
    margin: -1px 0px 0px 12px;
  }

  #boton-container button:hover {
    background-color: var(--naranja40) !important;
  }

  // Plazos Container
  #plazos-container {
    width: 230px !important;
    padding: 130px 0px 145px 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: flex-start;
    z-index: 96;
  }

  // Plazos Container Titulo
  #plazos-container p {
    width: 100%;
    margin: 0px 0px 12px 0px;
    font-size: 13px;
    font-weight: 600;
    color: var(--negro);
  }

  // Plazos Container Texto
  #plazos-texto {
    font-weight: 400 !important;
    color: var(--gris) !important;
  }

  // Nav Container Div
  #plazos-container div {
    width: 100%;
    margin: 12px 0px 0px 0px;
    padding: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-top: 1px solid var(--grisClaro);
  }

  // Plazos Container Botones
  #plazos-container a {
    width: auto;
    margin: 0px 40px 12px 0px;
    font-size: 13px;
    font-weight: 400;
    color: var(--gris);
    transition: 0.1s all;
  }

  // Nav Container Botones Hover
  #plazos-container a:hover {
    color: var(--naranja100);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Texto Container
    #formulario-container {
      width: 630px;
      padding: 80px 64px 80px 0px;
    }

    // Input Container
    .input-container {
      width: 35%;
    }

    // Input Email y Puesto
    #input-puesto-container,
    #input-email-container {
      margin: 32px 16px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Texto Container
    #formulario-container {
      width: 100%;
      padding: 80px 0px 80px 0px;
      border: 0;
    }

    // Plazos Container
    #plazos-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Input Container
    .input-container {
      width: 45%;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Datos Personales Titulo y Mensaje Motivo Titulo
    #datos-personales h4,
    #cv-container h4,
    #mensaje-motivo h4 {
      margin: 0px 0px 8px 0px;
    }

    // Input Container
    .input-container {
      width: 100%;
      margin: 24px 0px 0px 0px;
    }

    // Textarea Container
    #textarea-mensaje-container {
      width: 100%;
      margin: 24px 0px 0px 0px;
    }

    // Input Puesto y Linkedin
    #input-puesto-container,
    #input-linkedin-container,
    #input-email-container {
      margin: 24px 0px 0px 0px;
    }
  }
`;

export default Container;
