import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Dropdown Abierto y Dropdown Cerrado
  .dropdown-abierto,
  .dropdown-cerrado {
    width: 100%;
    max-width: 1185px;
    max-height: 115px;
    margin: 0 auto;
    padding: 24px 24px 24px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 0px;
    background-color: var(--blanco);
    border-radius: 16px !important;
  }

  // Mejor Precio y Recomendado Container
  .mejor-precio,
  .recomendado {
    width: auto;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--grisClaro2);
    border-radius: 6px 6px 0px 0px;
    position: absolute;
    top: -20px;
    left: 25px;
  }

  // Mejor Precio Container Background Effect
  .shimmerPrecio {
    background-image: linear-gradient(
      45deg,
      var(--grisClaro2) 0%,
      var(--grisClaro2) 40%,
      #b7b7b7 50%,
      #b7b7b7 55%,
      var(--grisClaro2) 65%,
      var(--grisClaro2) 100%
    );
    background-size: 300%;
    animation: 2500ms ease 0s infinite normal none running shimmer;
  }

  // Recomendado Container Background Effect
  .shimmerRecomendado {
    background-image: linear-gradient(
      45deg,
      #daa520 0%,
      #daa520 40%,
      #ffc600 50%,
      #ffc600 55%,
      #daa520 65%,
      #daa520 100%
    );
    background-size: 300%;
    animation: 2500ms ease 0s infinite normal none running shimmer;
  }

  // Apto Competencia Container Background Animation
  @keyframes shimmer {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0px 0px;
    }
  }

  // Mejor Precio y Recomendado Texto
  .mejor-precio p,
  .recomendado p {
    margin: 0px 0px 0px 0px;
    padding: 5px 15px 4px 15px;
    font-size: 11px;
    text-align: center;
    color: var(--blanco);
    letter-spacing: 0.5px;
  }

  // Mejor Precio y Recomendado Icon
  .mejor-precio p span,
  .recomendado p span {
    margin: 0px 5px 0px 0px;
    font-size: 10px;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 100%;
    height: 67px;
    max-width: 115px;
    min-height: 67px;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--naranja100);
    line-height: 10px;
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 855px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 100%;
    max-width: 120px;
    font-size: 24px;
    text-align: center;
    color: var(--verde50);

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 22px;
    color: var(--rojo) !important;
  }

  // Precio Container
  .precio-container {
    width: 100%;
    max-width: 135px;
    min-width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--gris);

    .cuotas-naranja {
      color: var(--naranja100) !important;
    }

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    margin: 0px 0px 0px 3px;
    text-decoration: line-through !important;
  }

  // Precio y oferta

  .precio-style {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.3px;
    text-align: center;
    color: var(--naranja100);
    margin: 4px 3px 4px 3px !important;
  }

  .precio-container div:nth-child(2) p {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.3px;
    text-align: center;
    color: var(--naranja100);
    margin: 4px 3px 4px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--lila90);
    line-height: normal;
  }

  // Botones Container
  .botones-container {
    width: 100%;
    max-width: 110px;
    padding: 0px 0px 0px 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  #contrata-boton {
    width: 100%;
    padding: 8px 24px 8px 24px;
    background-color: var(--naranja100);
    color: var(--blanco);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s all;
  }

  #contrata-boton.btn-big {
    padding: 8px;
    white-space: nowrap;
  }

  #contrata-boton:hover {
    background-color: var(--naranja40);
  }

  #ver-mas-boton {
    border: 0px;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
    background-color: transparent;
    font-size: 12px;
    text-align: center;
    color: var(--lila90);
    pointer-events: all;
    transition: 0.2s all;
  }

  #ver-mas-boton:hover {
    color: var(--negro);
  }

  // Text Container
  .children-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 550ms;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Dropdown Abierto y Dropdown Cerrado
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 1018px;
    }

    // Checks Container
    .checks-container {
      max-width: 690px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 476px !important;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 16px 0px 0px;
    }

    // Checks Container
    .checks-container {
      max-width: 167px;
    }

    // Checks Container Icono
    .checks-container span {
      display: none;
    }

    // Precio Container
    .precio-container {
      padding: 0px 16px 0px 16px;
    }

    // Botones Container
    .botones-container {
      border-left: 1px solid var(--grisClaro);
      padding: 0px 0px 0px 16px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 308px !important;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Botones Container
    .botones-container {
      border-left: 0px solid;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 350px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 100%;
      max-height: 182px;
    }

    // Logo y Plan Container
    .logo-plan-container {
      max-width: 100%;
      padding: 0px 0px 0px 0px;
      border: 0px;
    }

    // Botones Container
    .botones-container {
      max-width: 100%;
      padding: 32px 0px 0px 0px;
    }

    #contrata-boton {
      width: 51%;
    }

    #contrata-boton.btn-big {
      max-width: 100%;
      padding: 8px 32px;
    }

    #ver-mas-boton {
      width: 51%;
    }
  }
`;

export default Container;
