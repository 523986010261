import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  justify-content: center;

  // Logos Container
  .logos-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  // Logos Botones
  .logos-container a {
    width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Botón RUS
  #boton-rus {
    pointer-events: none;
  }



  // Logos Imagenes
  .logos-container a img {
    width: 21%;
    margin: 0px 24px 0px 24px;
    display: block;
    pointer-events: none;
    user-select: none;
  }

  #rus-logo {
    width: 145px;
  }

  #prudencia-logo {
    margin: 5px 24px 0px 24px;
    width: 140px;
  }

  #patronal-logo {
    width: 220px;
  }

  #lasegunda-logo{
    width: 170px;
  }

   #integrity-logo{
    width: 170px;
  }
    
  #sancor-logo{
    width: 170px;
  }

  // Marquee Container
  .fade-container-marquee {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;

    img {
      width: 21%;
      margin: 0px 24px 0px 24px;
      display: block;
      pointer-events: none;
      user-select: none;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Logos Container
    .logos-container {
      display: none;
    }

    // Marquee Container
    .fade-container-marquee {
      width: 680px;
      display: flex;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Marquee Container
    .fade-container-marquee {
      width: 100%;
    }
  }
`;

export default Container;
