import React from "react";

const InputBasic = ({ type, label, id, name, placeholder, onChange, onFocus, onBlur, value, error, ref }) => {
  return (
    <div className="input-container" id={`${id}-container`}>
      <label className="input-label" for={id}>
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        className={error == null ? "" : "input-error"}
        placeholder={placeholder}
        ref={ref}
      ></input>
      {error && <span>{error}</span>}
    </div>
  );
};

export default InputBasic;
