import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import Cotizador from "../components/cotizador";
import Logos from "../components/home/logos/index";
import Cubrimos from "../components/home/cubrimos/index";
import Pasos from "../components/home/pasos/index";
import Footer from "../components/footer/index";
// Librerias
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function BiciComercialContainer({ data }) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link
          rel="canonical"
          href="https://segurobici.com.ar/seguro-de-bicicleta-comercial"
        />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Cotizador */}
      <Cotizador
        subtitle="¡Asegurá tu bici (delivery) con nosotros!"
        landingVehiculo="comercial"
        marketing_url={data}
      ></Cotizador>
      {/* Logos */}
      <Logos></Logos>
      {/* Cosas que cubre el seguro */}
      <Cubrimos></Cubrimos>
      {/* Pasos para contratar el seguro */}
      <Pasos></Pasos>
      {/* Footer */}
      <Footer marcas="active" marketing_url={data}></Footer>
    </Container>
  );
}
