import React, { useState, useEffect } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { BsUpload } from "react-icons/bs";
import { BiInfoCircle } from "react-icons/bi";
import { CiFileOn } from "react-icons/ci";
import { RiEyeLine } from "react-icons/ri";
import disableScroll from "disable-scroll";
// Componentes
import ModalEjemplo from "../pasos/contrata/modalEjemplo";
// Custom Hooks
import { forwardRef } from "react";
import { useFile } from "../../hooks/useFile";
import { BiTrash } from "react-icons/bi";

const InputFileMultiple = forwardRef(
  (
    {
      archivos,
      handleChangeExternal,
      texto,
      nombre,
      id,
      tituloInfo,
      requisitos,
      peso,
      error,
      imagenModal,
      tituloModal,
      textoModal,
      label,
      hideRequeriments,
      type
    },
    ref
  ) => {
    // Subir y borrar archivos
    const { multipleFilesUpload, deleteFile } = useFile(handleChangeExternal);

    function formatFileSize(bytes,decimalPoint) {
      if(bytes == 0) return '0 Bytes';
      var k = 1000,
          dm = decimalPoint || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
   }
   

    return (
      <Container ref={ref}>
        {/* Input File Container */}
        <span className="input-label">{label}</span>
        <div id="input-file-container">
            <div id="carga-container">
              {error && (
                <img
                  alt="ícono check correcto"
                  src={process.env.PUBLIC_URL + "/images/icons/error.svg"}
                ></img>
              )}
              {/* Label / Botón */}
              <label for={id}>
                <span>
                  <BsUpload></BsUpload>
                </span>
                <p>{texto}</p>
                <p>{".png .jpg .jpeg .pdf"}</p>
              </label>
              {/* Input */}
              <input
                type="file"
                name={nombre}
                accept={"image/*, application/pdf"}
                id={id}
                multiple={true}
                onChange={(e) => multipleFilesUpload(e, archivos)}
              ></input>
            </div>
        </div>
        {error && <span className="error-input">{error}</span>}
        {archivos.length > 0 ?   
          <div className="grid-container">
              {archivos.map((archivo, index) => {
                console.log(archivo)
                return (
                  <div className="archivo-cargado-container">
                    <div className="archivo-cargado-informacion-container">
                      <a href={URL.createObjectURL(archivo.image)} target="_blank" rel="noreferrer"> 
                        <div className="archivo-cargado-imagen">
                          {archivo.image.type === "application/pdf" ? <span className="archivo-cargado-pdf"><CiFileOn /></span> : <img src={URL.createObjectURL(archivo.image)} alt={`Archivo cargado con el nombre ${archivo.name}`} />}
                        </div>
                      </a>
                      <div className="archivo-cargado-informacion">
                        <span className="archivo-cargado-nombre">{archivo.name}</span>
                        <span className="archivo-cargado-peso">{formatFileSize(archivo.size)}</span>
                      </div>
                    </div>
                    <div className="archivo-cargado-eliminar" onClick={() => deleteFile(archivo.name, archivos, nombre)}>
                      <span><BiTrash /></span>
                    </div>
                  </div>
                )
            })}
          </div>  
        : null}    
      </Container>
    );
  }
);

export default InputFileMultiple;
