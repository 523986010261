import React from "react";

const InputSelect = ({ label, id, name, placeholder, onChange, onFocus, onBlur, value, error, ref, options }) => {

  console.log(options)

  return (
    <div className="select-container" id={"select-material-container"}>
      <label className="input-label" for={id}>
        {label}
      </label>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={error == null ? "" : "input-error"}
        placerholder={placeholder}
        //ref={(element) => (ref.current[name] = element)}
      >
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {options.length > 0 && options.map(option => {
            return (
                <option value={option.value}>{option.label}</option>
            )
        })}
      </select>
      <div className="flecha-select-container">
        <i></i>
      </div>
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default InputSelect;
