import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Dropdown Abierto y Dropdown Cerrado
  .dropdown-abierto,
  .dropdown-cerrado {
    width: 100%;
    max-width: calc(840px - 30px);
    max-height: 90px;
    margin: 0 auto;
    padding: 17px 15px 17px 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 0px;
    background-color: var(--white);
    border-radius: 12px 12px 12px 12px !important;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 100%;
    max-width: 115px;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-right: 1px solid var(--lightgray2);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 88%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Nombre del Seguro
  .logo-plan-container p {
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--orange);
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 500px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 100%;
    max-width: 100px;
    font-size: 15.6px;
    text-align: center;
    color: var(--green);
  }

  // X Icono
  #check-no {
    color: red !important;
    font-size: 14px;
  }

  // Precio Container
  .precio-container {
    width: 100%;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
    font-family: Raleway;
    font-style: normal;
  }

  // Cuotas y precio anterior
  .precio-container div:nth-child(1) {
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    color: var(--lightgray);

    #precio-anterior {
      text-decoration: line-through;
      font-weight: bold;
    }

    .cuotas-naranja {
      color: var(--orange) !important;
    }

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio y oferta
  .precio-container div:nth-child(2) p {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: var(--orange);
    margin: 6px 0px 6px 0px;
  }

  // Contratando
  .precio-container div:nth-child(3) {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--orange);
  }

  // Botones Container
  .botones-container {
    width: 100%;
    max-width: 110px;
    padding: 0px 0px 0px 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  #contrata-boton {
    width: 100%;
    background-color: var(--orange);
    padding: 10px 0px 10px 0px;
    border: 0;
    color: var(--white);
    cursor: pointer;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    border-radius: 6px;
    transition: 0.2s all;
    pointer-events: all;
  }

  #contrata-boton:hover {
    background-color: var(--lightorange);
  }

  #ver-mas-boton {
    border: 0px;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
    background-color: transparent;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: var(--orange);
    pointer-events: all;
    transition: 0.2s all;
  }

  #ver-mas-boton:hover {
    color: var(--black);
  }

  // Text Container
  .children-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 350ms;
  }

  // 940px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet940}) {
    // Dropdown Abierto y Dropdown Cerrado
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: calc(340px - 30px);
    }

    // Checks Container
    .checks-container {
      display: none;
      max-width: 300px;
    }

    // Checks Container Icono
    .checks-container span {
      max-width: 60px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Header Bar Open and Close
    .headerBarOpen,
    .headerBarClose {
      pointer-events: initial;
      cursor: pointer;
      justify-content: center;
    }

    // Button
    .button {
      flex-wrap: wrap;
      flex-direction: row;
      pointer-events: all;
      justify-content: center;
    }

    // Logo and Plan Seguro
    .logoPlan {
      border-right: 0px;
      padding: 0px 15px 0px 0px;
    }

    // Informacion de precios Container
    .priceData {
      padding: 0px 0px 0px 15px;
    }

    // Arrow Container
    .arrowContainer {
      display: flex;
      padding: 0px 0px 0px 34px;
    }

    // Buttons Container
    .buttonsContainer {
      max-width: none;
      min-width: none;
      width: 35%;
      padding: 0px 0px 0px 0px;
      margin: 20px 0px 0px 0px;
      border-left: 0px;
    }

    #verMasButton {
      display: none;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Logo and Plan Seguro
    .logoPlan {
      padding: 0px 5px 0px 0px;
    }

    // Informacion de precios Container
    .priceData {
      padding: 0px 0px 0px 5px;
    }

    // Arrow Container
    .arrowContainer {
      display: flex;
      padding: 0px 0px 0px 24px;
    }
  }

  // 375px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile375}) {
    // Logo and Plan Seguro
    .logoPlan {
      padding: 0px 2px 0px 0px;
    }

    // Informacion de precios Container
    .priceData {
      padding: 20px 0px 0px 2px;
    }

    // Arrow Container
    .arrowContainer {
      display: flex;
      padding: 0px 0px 0px 15px;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Logo and Plan Seguro
    .logoPlan {
      width: 100%;
      padding: 0px 0px 25px 0px;
    }

    // Informacion de precios Container
    .priceData {
      width: 90%;
      padding: 0px 25px 0px 0px;
    }

    // Arrow Container
    .arrowContainer {
      width: 10%;
      padding: 0px 0px 0px 0px;
    }
  }
`;

export default Container;
