import React, { useState } from "react";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import Headroom from "react-headroom";
import TitleSection from "../../components/TitleSection";
import Container from "./styled";
import InnerContainer from "../../components/InnerContainer";
import Section from "../../components/Section";
import { useForm } from "../../hooks/useForm";
import { formaParteReglas } from "../../utils/formRules";
import { formaParteForm } from "../../utils/formInitialValues";
import ContactoForm from "../../components/Forms/ContactoForm";
import Aside from "../../components/Aside";
import { Link } from "react-router-dom";
import { goToTop } from "../../utils/scrollToTop";
import DataContainer from "../../components/DataContainer";
import MessageReceived from "../../components/MessageReceived";

const Contacto = () => {
  const [messageSent, setMessageSent] = useState(false);

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal,
  } = useForm(handleSubmit, formaParteReglas, formaParteForm);

  console.log(formErrors)

  async function handleSubmit() {
    try {
      //await sendFormMail(inputValues, pasoSiguiente, contactoMail);
      //pasoSiguiente()
      setMessageSent(true)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar/contacto" />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <TitleSection
        tituloSpan="Contacto"
        titulo=""
        tag="Recursos humanos"
        descripcion="Comunicate con nosotros"
      />
      {/* Formulario */}
      <InnerContainer>
        <DataContainer>
          {!messageSent ? (
            <>
              <Section
                title="¡Comunicate con nuestro equipo!"
                description={
                  "Completá el siguiente formulario y envianos un mensaje con tus consultas, pronto nos comunicaremos para ayudarte."
                }
              />
              <ContactoForm
                formValues={{
                  inputValues,
                  handleChange,
                  handleChangeExternal,
                  handleValidation,
                  formErrors,
                }}
              />
              <div id="mapa-container">
                <h3>Encontranos en nuestra oficina</h3>
                {/* Mapa */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.952818422825!2d-58.38140768382813!3d-34.60535456509365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccace477850f1%3A0x4c973e8332061355!2sGesti%C3%B3n%20y%20Servicios%20S.R.L.!5e0!3m2!1ses-419!2sar!4v1639418264150!5m2!1ses-419!2sar"
                  width="100%"
                  height="350"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
                <div id="transporte-container">
                  <h3>Transporte cercano:</h3>
                  <div>
                    <h4>Colectivos:</h4>
                    <span id="linea115">115</span>
                    <span id="linea130">130</span>
                    <span id="linea140">140</span>
                    <span id="linea180">180</span>
                    <span id="linea45">45</span>
                    <span id="linea50">50</span>
                  </div>
                  <div>
                    <h4>Subtes:</h4>
                    <span id="subteB">B</span>
                    <span id="subteC">C</span>
                    <span id="subteD">D</span>
                  </div>
                  <div id="trenes">
                    <h4>Trenes:</h4>
                    <span>Belgrano Norte</span>
                    <span>Mitre</span>
                    <span>Roca</span>
                    <span>Sarmiento</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <MessageReceived title="¡Recibimos tu contacto con éxito!" description={"Pronto nos pondremos en contacto para charlar más con vos." }/>
          )}
         </DataContainer> 
                
         <Aside>
          <p>Dirección:</p>
          <p id="plazos-texto">Suipacha 245 1° Piso, CABA.</p>
          <div>
            <p>Teléfonos</p>
            <p id="plazos-texto">
              0810-220-2373<br></br>(011) 5263-2373
            </p>
          </div>
          <div>
            <p>Email</p>
            <p id="plazos-texto">info@seguroweb.com.ar</p>
          </div>
          <div>
            <p>Ver más</p>
            <Link to="/forma-parte" onClick={goToTop}>
              Forma parte
            </Link>
            <Link to="/sobre-nosotros">Sobre nosotros</Link>
          </div>
        </Aside>
      </InnerContainer>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
};

export default Contacto;
