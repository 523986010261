// *** Cotizador *** //
export const cotizadorForm = {
  nombre:
  localStorage.getItem("segurobici-nombre") !== null
    ? localStorage.getItem("segurobici-nombre")
    : "",
  apellido:
    localStorage.getItem("segurobici-apellido") !== null
      ? localStorage.getItem("segurobici-apellido")
      : "",
  email:
    localStorage.getItem("segurobici-email") !== null
      ? localStorage.getItem("segurobici-email")
      : "",
  provincia:
    localStorage.getItem("segurobici-provincia") !== null
      ? localStorage.getItem("segurobici-provincia")
      : "",
  telefono:
    localStorage.getItem("segurobici-telefono") !== null
      ? localStorage.getItem("segurobici-telefono")
      : "",
  codigoArea:
    localStorage.getItem("segurobici-codigoArea") !== null
      ? localStorage.getItem("segurobici-codigoArea")
      : "",
  vehiculo: ''
  /*
  vehiculo:
    landingVehiculo == "segurodebicicleta"
      ? "bicicleta"
      : landingVehiculo == "comercial"
      ? "bicicleta (delivery)"
      : landingVehiculo == "electrica"
      ? "bicicleta electrica"
      : landingVehiculo == "monopatin"
      ? "monopatin electrico"
      : localStorage.getItem("segurobici-vehiculo") !== null
      ? localStorage.getItem("segurobici-vehiculo")
      : "bicicleta",
  */
};

// *** Contratá *** //
export const contrataForm = {
  nombre: localStorage.getItem("nombre-segurobici") ? localStorage.getItem("nombre-segurobici") : "",
  apellido: localStorage.getItem("apellido-segurobici") ? localStorage.getItem("apellido-segurobici") : "",
  email: localStorage.getItem("email-segurobici") ? localStorage.getItem("email-segurobici") : "",
  codigo_area: localStorage.getItem("codigo-area-segurobici") ? localStorage.getItem("codigo-area-segurobici") : "",
  telefono: localStorage.getItem("telefono-segurobici") ? localStorage.getItem("telefono-segurobici") : "",
  localidad: localStorage.getItem("segurobici-provincia"),
  vehiculo: localStorage.getItem("segurobici-vehiculo"),
  suma_asegurada: localStorage.getItem("segurobici-sumaasegurada"),
  marca: "",
  modelo: "",
  color: "",
  rodado: "",
  material: "",
  transmision: "",
  inputNumeroSerie: "",
  tamañoCuadro: "",
  linkMercadoLibre: "",
  // Datos forma de pago
  formaDePago: "Tarjeta de credito",
  numeroTarjeta: "",
  bancoEmisor: "",
  vencimiento: "",
  titular: "",
  cuitDNI: "",
  codigo_postal: "",
  cvc: "",
  cbu: "",
  dniTitular: "",
  tipo_tarjeta: "",
  // Archivos:
  archivo_bicicleta: "",
  archivo_num_serie_bicicleta: "",
  archivo_factura_bicicleta: "",
  archivo_presupuesto_bicicleta: "",
  archivo_video: "",
  archivo_frente_dni: ""
}

// *** Arrepentimiento *** //
export const arrepentimientoForm = {
  nombre:
    localStorage.getItem("nombre-segurobici") !== null
      ? localStorage.getItem("nombre-segurobici")
      : "",
  apellido:
    localStorage.getItem("apellido-segurobici") !== null
      ? localStorage.getItem("apellido-segurobici")
      : "",
  email:
    localStorage.getItem("email-segurobici") !== null
      ? localStorage.getItem("email-segurobici")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-segurobici") !== null
      ? localStorage.getItem("telefono-segurobici")
      : "",
  mensaje: '',
  captcha: undefined
}

// *** Baja *** //
export const bajaServicioForm = {
  nombre:
    localStorage.getItem("nombre-segurobici") !== null
      ? localStorage.getItem("nombre-segurobici")
      : "",
  apellido:
    localStorage.getItem("apellido-segurobici") !== null
      ? localStorage.getItem("apellido-segurobici")
      : "",
  email:
    localStorage.getItem("email-segurobici") !== null
      ? localStorage.getItem("email-segurobici")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-segurobici") !== null
      ? localStorage.getItem("telefono-segurobici")
      : "",
  mensaje: '',
  captcha: undefined
}

// *** Contacto *** //
export const contactoForm = {
  nombre:
  localStorage.getItem("nombre-segurobici") !== null
    ? localStorage.getItem("nombre-segurobici")
    : "",
  email:
    localStorage.getItem("email-segurobici") !== null
      ? localStorage.getItem("email-segurobici")
      : "",
  telefono:
    localStorage.getItem("telefono-segurobici") !== null
      ? localStorage.getItem("telefono-segurobici")
      : "",
  asunto: '',
  mensaje: '',
  captcha: undefined
}

// *** Referidos *** //
export const referidosForm = {
  email:
    localStorage.getItem("email-segurobici") !== null
      ? localStorage.getItem("email-segurobici")
      : "",
  emailReferido: "",
  nombreReferido: "",
  captcha: undefined
}

// *** Forma Parte *** //
export const formaParteForm = {
  nombre:
    localStorage.getItem("nombre-segurobici") !== null
    ? localStorage.getItem("nombre-segurobici")
    : "",
  apellido:
    localStorage.getItem("apellido-segurobici") !== null
      ? localStorage.getItem("apellido-segurobici")
      : "",
  email:
    localStorage.getItem("email-segurobici") !== null
      ? localStorage.getItem("email-segurobici")
      : "",
  puesto: '',
  mensaje: '',
  cv: '',
  captcha: undefined
}

// *** Red de Bicicleterias *** //
export const redBicicleteriasForm = {
  nombreBicicleteria: '',
  nombreyapellido: '',
  email: '',
  telefono: ''
}

// *** Red de Bicicleterias *** //
export const crearSiniestroForm = {
  email: '',
  codigo_area: '',
  telefono: '',
  producto: '',
  tipo: '',
  provincia: '',
  localidad: '',
  fecha: '',
  hora: '',
  calle: '',
  altura: '',
  descripcion: '',
  archivo_foto_dni: '',
  archivo_denuncia_policial: '',
  archivo_foto_bien_siniestrado: '',
  archivo_extras: []
}