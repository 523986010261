import React from "react";
import Container from "./styled";
import { Link } from "react-scroll";
import { Fade } from "react-awesome-reveal";

export default function Beneficios() {
  return (
    <Container>
      <div id="max-width-container">
        {/* Titulo */}
        <Fade delay={100} duration={800} triggerOnce={true}>
          <h3>
            <span>Beneficios</span> de trabajar con nosotros
          </h3>
        </Fade>
        {/* Beneficios Container */}
        <div id="beneficios-container">
          {/* Beneficio 1 */}
          <Fade delay={100} duration={800} triggerOnce={true}>
            <div>
              <h4 id="primer-titulo">
                Vender bicicletas <br></br>de mayor valor
              </h4>
              <p>
                Con el seguro, el cliente ya no tendrá la preocupación de perder
                ese patrimonio.
              </p>
            </div>
          </Fade>
          {/* Beneficio 2 */}
          <Fade delay={100} duration={800} triggerOnce={true}>
            <div>
              <h4>
                Fidelizar a<br></br>sus clientes
              </h4>
              <p>
                Posibilidad de que el cliente se lleve la bicicleta asegurada
                desde su bicicletería.
              </p>
            </div>
          </Fade>
          {/* Beneficio 3 */}
          <Fade delay={100} duration={800} triggerOnce={true}>
            <div>
              <h4>
                Premios<br></br>y beneficios
              </h4>
              <p>
                SeguroBici reconoce tus logros con distintos beneficios y premios.
              </p>
            </div>
          </Fade>
          {/* Beneficio 4 */}
          <Fade
            delay={100}
            duration={800}
            triggerOnce={true}
            className="beneficio-4"
          >
            <div>
              <h4>
                Red de<br></br>bicicleterías
              </h4>
              <p>
                Formar parte de la extensa red de bicicleterías de SeguroBici.
              </p>
            </div>
          </Fade>
          {/* Beneficio 5 
          <Fade delay={100} duration={800} triggerOnce={true}>
            <div>
              <h4>
                Red de<br></br>reposición
              </h4>
              <p>
                Adquisición y reposición de bicicletas en su bicicletería para
                los clientes.
              </p>
            </div>
          </Fade> */}
          {/* Beneficio 6 */}
          <Fade
            delay={100}
            duration={800}
            triggerOnce={true}
            className="beneficio-6"
          >
            <div>
              <h4>
                Difusión<br></br>y publicidad
              </h4>
              <p>
                Acceso a publicar sus productos y promociones en nuestros
                canales.
              </p>
            </div>
          </Fade>
        </div>
        {/* Forma Parte Button */}
        <Fade
          delay={100}
          duration={800}
          triggerOnce={true}
          className="boton-container"
        >
          <Link to="forma-parte-formulario" smooth={true} spy={true} duration={500}>
            ¡Formá parte!
          </Link>
        </Fade>
      </div>
    </Container>
  );
}
