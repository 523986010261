import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 22px;
  }

  // Titulo Mapa Container
  #mapa-container h3 {
    margin: 48px 0px 0px 0px;
  }

  // Texto Container
  #formulario-container {
    width: 700px;
    margin: 0px 0px 0px 0px;
    padding: 80px 200px 160px 0px;
    border-right: 1px solid var(--grisClaro);
  }

  // Texto
  #formulario-container p {
    width: 100%;
    text-align: left;
    font-size: 15px;
    color: var(--gris);
    line-height: 32px;
  }

  // Mapa Container
  #mapa-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    border-top: 1px solid var(--grisClaro);
  }

  // Plazos Container
  #plazos-container {
    width: 230px !important;
    padding: 130px 0px 145px 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: flex-start;
    z-index: 96;
  }

  // Plazos Container Titulo
  #plazos-container p {
    width: 100%;
    margin: 0px 0px 12px 0px;
    font-size: 13px;
    font-weight: 600;
    color: var(--negro);
  }

  // Plazos Container Texto
  #plazos-texto {
    font-weight: 400 !important;
    color: var(--gris) !important;
  }

  // Nav Container Div
  #plazos-container div {
    width: 100%;
    margin: 12px 0px 0px 0px;
    padding: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-top: 1px solid var(--grisClaro);
  }

  // Plazos Container Botones
  #plazos-container a {
    width: auto;
    margin: 0px 40px 12px 0px;
    font-size: 13px;
    font-weight: 400;
    color: var(--gris);
    transition: 0.1s all;
  }

  // Nav Container Botones Hover
  #plazos-container a:hover {
    color: var(--naranja100);
  }

  // Mapa Container Mapa
  #mapa-container iframe {
    margin: 40px 0px 0px 0px;
    border: 0px;
    border-radius: 16px;
  }

  // Transporte Container
  #transporte-container {
    width: 100%;
    margin: 48px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Transporte Container Titulo
  #transporte-container h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }

  // Transporte Container Div
  #transporte-container div {
    width: auto;
    margin: 32px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Transporte Container Div Texto
  #transporte-container div h4 {
    width: 100%;
    margin: 0px 0px 16px 0px;
  }

  // Transporte Container Div Span
  #transporte-container div span {
    width: auto;
    margin: 0px 4px 0px 4px;
    padding: 3px 8px 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--blanco);
    font-size: 12px;
    font-weight: 600;
    background-color: var(--naranja100);
    border-radius: 4px;
  }

  // Colores de lineas y ramales
  #linea115,
  #linea180,
  #subteB {
    background-color: #fe0034 !important;
  }

  #linea130,
  #linea45,
  #linea50,
  #subteD {
    background-color: #346702 !important;
  }

  #linea140 {
    background-color: #999999 !important;
  }

  #subteC {
    background-color: #0168b5 !important;
  }

  #trenes span {
    background-color: #0090d6 !important;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Texto Container
    #formulario-container {
      width: 630px;
      padding: 80px 64px 80px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Texto Container
    #formulario-container {
      width: 100%;
      padding: 80px 0px 80px 0px;
      border: 0;
    }

    // Plazos Container
    #plazos-container {
      display: none;
    }
  }
`;

export default Container;
