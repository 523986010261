import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Compañias Container
  .max-width-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Consultá Container
  .consulta-container {
    width: 100%;
    max-width: 1200px;
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  // Consultá
  .consulta-container div {
    width: fit-content;
    padding: 15px 15px 15px 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    background-color: var(--white);
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.15);
    border-radius: 12px;
  }

  // Consultá Botón
  .consulta-container div button {
    padding: 12.5px 15px 12.5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--white);
    background-color: var(--orange);
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Consultá Botón Hover
  .consulta-container div button:hover {
    background-color: var(--lightorange);
  }

  // Consultá Ícono
  .consulta-container div span {
    margin: 0px 4px 0px 0px;
    font-size: 11px;
  }

  // Consultá Texto
  .consulta-container div p {
    margin: 0px 2px 0px 15px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    line-height: 20px;
  }

  // Consulta Texto Span
  .consulta-container div p span {
    font-weight: 400;
    font-size: 12px;
  }

  // Seleccione Compañia Container
  .seleccione-compañias {
    width: 100%;
    margin: 45px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Seleccione Compañia Container Text
  .seleccione-compañias p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  // Seleccione Compañia Container Text 2
  .seleccione-compañias p:nth-child(2) {
    width: 40%;
    min-width: 480px;
    margin: 20px 0px 0px 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }

  // Seleccione Compañia Container Span
  .seleccione-compañias span {
    margin: 0px 7px 0px 0px;
    font-size: 25px !important;
    color: var(--orange);
  }

  // Referencias Container
  .referencias-container {
    width: 100%;
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Referencias
  .referencias {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
  }

  // Referencias Texto
  .referencias p {
    width: 100%;
    max-width: 100px;
    margin: 0px 0px 0px 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  // Dropdowns Container
  .dropdowns-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Dropdown Container
  .dropdown-container {
    width: 100%;
    max-width: calc(840px - 30px);
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--white);
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.1);
    transition: 0.2s all;
  }

  // Datos Container
  .datos-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Seguro Container
  .seguro-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Seguro Container Text
  .seguro-container p {
    margin: 0px 4px 0px 4px;
    text-align: center;
    font-size: 13.5px;
    font-weight: 700;
    color: var(--orange);
  }

  // Mas Información Container
  .mas-informacion-container {
    width: 100%;
    margin: 25px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Mas Información Container
  .mas-informacion-container p {
    width: 55%;
    margin: 15px 0px 0px 0px;
    text-align: center;
    font-size: 13px;
    line-height: 24px;
  }

  // Botón Seguro Container
  .boton-seguro-container {
    width: 100%;
    margin: 30px 0px 35px 0px;
  }

  // Botón Seguro Container Botones
  .boton-seguro-container a {
    padding: 10px 20px 10px 20px;
    background-color: var(--orange);
    color: var(--white);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Seguro Container Hover
  .boton-seguro-container a:hover {
    background-color: var(--lightorange);
  }

  // Form Botón
  #form-boton {
    margin: 50px 0px 0px 0px;
    padding: 12.5px 15px 12.5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--white);
    background-color: var(--orange);
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Form Botón Hover
  #form-boton:hover {
    background-color: var(--lightorange);
  }

  // 940px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet940}) {
    // Consultá Container
    .consulta-container {
      width: 80%;
    }

    // Consultá
    .consulta-container div {
      width: 310px;
      padding: 15px 15px 15px 15px;
      flex-wrap: wrap-reverse;
    }

    // Consultá Texto
    .consulta-container div p {
      width: auto;
      margin: 0px 0px 0px 0px;
      text-align: center;
    }

    // Consultá Botón
    .consulta-container div button {
      margin: 20px 0px 0px 0px;
    }

    // Dropdowns Container
    .dropdowns-container {
      width: calc(340px - 30px);
    }

    // Dropdown Container
    .dropdown-container {
      max-width: calc(340px - 30px);
    }

    // Referencias
    .referencias {
      display: none;
      max-width: 300px;
    }

    // Referencias Texto
    .referencias p {
      max-width: 60px;
      font-size: 10px;
    }
  }

  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
    // Seleccione Compañia Container Text
    .seleccione-compañias p {
      width: 90%;
    }

    // Seleccione Compañia Container Text 2
    .seleccione-compañias p:nth-child(2) {
      width: 90%;
      min-width: 90%;
    }
  }
`;

export default Container;
