import axios from "axios"
import url from "../utils/url";

export const postearContratacion = async (dataPago) => {
    return await axios.post(`${url}/api/contratacion/`, dataPago);
}

export const postearConsulta = async (idCotizacion, nombreCompania, plan) => {
    return await axios.post(`${url}/api/sendEmails/consulta/${idCotizacion}`, {
        compania: nombreCompania,
        plan: plan,
    });
}