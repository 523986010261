import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 48px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  // Puntos de fondo
  background: linear-gradient(
        90deg,
        var(--celeste90) calc(22px - 1.4px),
        transparent 1%
      )
      center / 22px 22px,
    linear-gradient(var(--celeste90) calc(22px - 1.4px), transparent 1%) center /
      22px 22px,
    #98a5be;
  border-top: 1px solid var(--gris80);

  // Max Width Container
  #max-width-container {
    width: 1188px;
    margin: 0px 0px 0px 0px;
    padding: 72px 0px 80px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Tag Container
  #tag-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // Tag
    div {
      width: auto;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    // Tag Text
    p {
      width: auto;
      margin: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      color: var(--lila80);
      line-height: 154.7%;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 28px 0px 0px 0px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    letter-spacing: -0.015em;
    line-height: 42px;

    // Span
    span {
      font-weight: 500;
      color: var(--naranja100);
    }
  }

  // Estrellas Container
  #estrellas-container {
    width: auto;
    margin: 40px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;

    // Texto Título Container
    div {
      width: 100%;
      margin: 12px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      user-select: none;
      pointer-events: none;
    }

    h3 {
      width: auto;
      margin: 0px 16px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 64px;
      color: var(--lila80);
    }

    h4 {
      width: auto;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      text-align: left;

      span {
        color: var(--lila80);
      }
    }

    // Estrellas
    img {
      width: 110px;
      margin: 0px 8px 0px 8px;
      user-select: none;
      pointer-events: none;
    }
  }

  #texto {
    width: 55%;
    margin: 32px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    letter-spacing: -0.015em;
    color: var(--lila90);
    -webkit-font-smoothing: subpixel-antialiased;
  }

  // Cards Container
  .carousel {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 80px 0px 0px 0px;
    background-color: transparent;
  }

  .carousel-row {
    width: 377.3px;
    display: flex;
    justify-content: center;
  }

  .carousel-track {
    width: 100%;
    justify-content: space-between !important;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }

    .carousel-row {
      width: 312px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }

    .carousel-row {
      width: 100%;
    }

    #texto {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 400px;
    }

    // Estrellas Container
    #estrellas-container {
      width: 100%;

      // Estrellas
      img {
        display: none;
      }
    }

    #texto {
      margin: 64px 0px 0px 0px;
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
