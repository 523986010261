import { useState } from "react";

export const useQuestion = () => {

  const [isQuestionOpen, setIsQuestionOpen] = useState(null);

  const handleToggle = (index) => setIsQuestionOpen(isQuestionOpen === index ? null : index)

  return { isQuestionOpen, handleToggle }

}