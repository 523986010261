import React from "react";
import { useState, useEffect } from "react";
// Librerías y estilos globales
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import GlobalStyle from "./constants/globalStyles";
import ReactGa from "react-ga";
// Home y Pasos
import HomePage from "./pages/home-page";
import PasosPage from "./pages/pasos-page";
// Red y Referidos
import ReferidosPage from "./pages/referidos-page";
import RedDeBicicleteriasPage from "./pages/red-de-bicicleterias-page";
// Ayuda
import ContactoPage from "./pages/contacto-page";
// Legales y La empresa
import InstitucionalPage from "./pages/institucional-page";
import FormaPartePage from "./pages/formaparte-page";
// Bicicleteria especifica
import BicicleteriaEspecificaPage from "./pages/bicicleteria-especifica-page";
// Cotizadores por vehículos
import BiciComercialPage from "./pages/seguro-de-bici-comercial-page";
import BiciElectricaPage from "./pages/seguro-de-bici-electrica-page";
import BicicletaPage from "./pages/seguro-de-bicicleta-page";
import MonopatinPage from "./pages/seguro-de-monopatin-page";
// Siniestros
import SiniestrosPage from "./pages/siniestros-page";
// Otros imports
import axios from "axios";
import url from "../src/utils/url";
import { SocketProvider } from "./context/SocketProvider";
import Test from "./pages/test";
import Contacto from "./pages/Contacto";
import Referidos from "./pages/Referidos";
import FormaParte from "./pages/FormaParte";
import SobreNosotros from "./pages/SobreNosotros";
import PoliticasTerminos from "./pages/PoliticasTerminos"
import Planes from "./pages/Planes";
import Siniestros from "./pages/Siniestros"

function App() {
  useEffect(() => {
    ReactGa.initialize("UA-65753722-6");
    // Reportes de visitas a la página
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const [urls, setUrls] = useState({});
  const [cotizador, setCotizador] = useState("");
  const [blogRoute, setBlogRoute] = useState(false);
  const [blogURL, setBlogURL] = useState("");
  const location = useLocation();

  const urlsCanales = ["/", "/gs", "/gd", "/in", "/fb", "/tiktok", "/twitch"];

  const cotizadoresToRender = [
    {
      component: BicicletaPage,
      producto: "Bicicleta",
    },
    {
      component: BiciComercialPage,
      producto: "Bicicleta (Delivery)",
    },
    {
      component: BiciElectricaPage,
      producto: "Bicicleta eléctrica",
    },
    {
      component: MonopatinPage,
      producto: "Monopatín eléctrico",
    },
    {
      component: HomePage,
      producto: "Todos",
    },
  ];

  // Redirección al blog de SeguroBici
  useEffect(() => {
    if (location.pathname.includes("/blog")) {
      const paths = location.pathname.split("/");
      const filteredPaths = paths
        .filter((path) => path !== "" && path !== "blog")
        .join("/");
      setBlogRoute(true);
      setBlogURL(`https://blog.segurobici.com.ar/${filteredPaths}`);
    }
  }, [location]);

  const RemoveTrailingSlash = ({ ...rest }) => {
    const location = useLocation();
    // If the last character of the url is '/'
    if (location.pathname.match("/.*/$")) {
      return (
        <Navigate
          replace
          {...rest}
          to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search,
          }}
        />
      );
    } else return null;
  };

  return (
    <div className="App">
      <SocketProvider>
      <RemoveTrailingSlash />
      <Routes>
        {/* ------------------ Home y Pasos ------------------ */}

        {/* Home (Redirecciona las urls con sufijos de los canales a la home) */}
        {urlsCanales.map((path, index) => {
          return <Route path={path} element={<HomePage />} key={index} />;
        })}
        {urls && cotizador && (
          <Route
            exact
            path={urls.url_marketing_url}
            element={<cotizador.component data={urls} />}
          />
        )}
        {!urls && <Route path="/*" element={<Navigate replace to="/" />} />}
        {blogRoute && (
          <Route
            exact
            path={location.pathname}
            element={<BlogURL url={blogURL} />}
          />
        )}

        <Route exact path="/asap-consulting" element={<HomePage />} />

        {/* Pasos */}
        <Route exact path="/pasos" element={<PasosPage />} />

        {/* Planes */}
        <Route exact path="/planes" element={<Planes />} />

        {/* ------------------ Red y Referidos ------------------ */}

        {/* Referidos */}
        <Route exact path="/referidos" element={<Referidos />} />
        {/* Red de Bicicleterias */}
        <Route
          exact
          path="/red-de-bicicleterias"
          element={<RedDeBicicleteriasPage />}
        />
        {/* Red de Bicicleterias (Redirección)*/}
        <Route
          exact
          path="/bicicleterias"
          element={<Navigate replace to="/red-de-bicicleterias" />}
        />

        {/* ------------------ Ayuda ------------------ */}

        {/* Contacto */}
        <Route exact path="/contacto" element={<Contacto />} />

        {/* ------------------ Legales y La empresa ------------------ */}

        {/* Políticas y términos 
        <Route
          exact
          path="/politicas-y-terminos"
          element={
            <InstitucionalPage
              pagina="politicas-y-terminos"
              tituloSpan="Políticas y términos"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        */}
        {/* Sobre Nosotros 
        <Route
          exact
          path="/sobre-nosotros"
          element={
            <InstitucionalPage
              pagina="sobre-nosotros"
              tituloSpan="Sobre nosotros"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        */}
        <Route
          exact
          path="/sobre-nosotros"
          element={<SobreNosotros />}
        />
        <Route
          exact
          path="/politicas-y-terminos"
          element={<PoliticasTerminos />}
        />
        {/* Formá Parte */}
        <Route exact path="/forma-parte" element={<FormaParte />} />

        {/* ------------------ Bicicleteria Especifica ------------------ */}

        {/* Bicicleteria Especifica */}
        <Route
          path="/bicicleterias/:bicicleteria"
          element={<BicicleteriaEspecificaPage />}
        />

        {/* ------------------ Cotizadores por vehículos ------------------ */}

        <Route
          exact
          path="/bicicleterias"
          element={<Navigate replace to="/red-de-bicicleterias" />}
        />
        {/* Bicicleta Comercial */}
        <Route
          path="/seguro-de-bicicleta-comercial"
          element={<BiciComercialPage />}
        />
        {/* Bicicleta Eléctrica */}
        <Route
          path="/seguro-de-bicicleta-electrica"
          element={<BiciElectricaPage />}
        />
        {/* Monopatín Eléctrico */}
        <Route
          path="/seguro-de-monopatin-electrico"
          element={<MonopatinPage />}
        />
        {/* Bicicleta Común */}
        <Route exact path="/seguro-de-bicicleta" element={<BicicletaPage />} />

        {/* ------------------ Siniestros ------------------ */}

        {/* Siniestros Page */}
        <Route path="/siniestros" element={<Siniestros />} />

        <Route path="/test" element={<Test />} />

      </Routes>
      <GlobalStyle></GlobalStyle>
      </SocketProvider>
    </div>
  );
}

// Función de redirección al Blog
function BlogURL({ url }) {
  window.location.replace(url);
  return null;
}

export default App;
