import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Fade Container
  .fade-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    margin: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  // Carousel Container
  #carousel-container {
    max-width: 590px;
    margin: 0px 48px 0px 0px;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border-radius: 12px;
    border: 1px solid var(--grisClaro);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  // Imagen Container
  #carousel-container div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  // Imagen
  #carousel-container div img {
    display: block;
    width: 100%;
    padding: 64px 116px 32px 116px;
    margin: 0 auto;
  }

  // Carousel Logo
  .bici-logo {
    padding: 0px !important;
    margin: 0px 0px 64px 0px !important;
    display: block;
    width: 100%;
    max-width: 120px;
    max-height: 20px;
    object-fit: contain;
  }

  // Carousel Text
  .bici-texto {
    padding: 0px !important;
    margin: 0px 0px 64px 0px !important;
    display: block;
    width: 100%;
    max-width: 120px;
    max-height: 16px;
    min-height: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2em;
  }

  // Carousel Arrows
  #carousel-container button {
    opacity: 100 !important;
    padding: 15px !important;
    transition: 0.2s all !important;
    color: var(--negro) !important;
  }

  #carousel-container button::before {
    border: none !important;
    content: url("/images/home/bicisAseguradas/arrows/arrowR.svg") !important;
    transform: scale(0.9) !important;
  }

  #carousel-container button:nth-child(1)::before {
    content: url("/images/home/bicisAseguradas/arrows/arrowL.svg") !important;
    transform: scale(0.9) !important;
  }

  #carousel-container button:hover {
    background-color: transparent !important;
    transform: scale(1.2) !important;
  }

  // Información Container
  #informacion-container {
    max-width: 565px;
  }

  // Titulo
  #informacion-container h3 {
    width: 100%;
    margin: 0px 0px 40px 0px;
    line-height: 40px;
  }

  // Titulo Span
  #informacion-container h3 span {
    color: var(--naranja100);
  }

  // Texto
  #informacion-container p {
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    line-height: 192.7%;
    letter-spacing: -0.015em;
    color: #747474;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Carousel Container
    #carousel-container {
      max-width: 571px;
      margin: 0px 40px 0px 0px;
    }

    // Información Container
    #informacion-container {
      max-width: 341px;
      margin: 0px 0px 0px 40px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
      justify-content: center;
    }

    // Carousel Container
    #carousel-container {
      max-width: 571px;
      margin: 0px 0px 0px 0px;
    }

    // Información Container
    #informacion-container {
      max-width: 100%;
      margin: 64px 0px 0px 0px;
    }

    // Titulo
    #informacion-container h3 {
      margin: 0px 0px 0px 0px;
      text-align: center;
    }

    // Texto
    #informacion-container p {
      text-align: center;
      margin: 24px 0px 0px 0px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 480px;
    }

    // Carousel Container
    #carousel-container {
      max-width: 100%;
    }

    // Imagen
    #carousel-container div img {
      display: block;
      width: 100%;
      padding: 32px 80px 32px 80px;
      margin: 0 auto;
    }

    // Carousel Logo
    .bici-logo {
      margin: 0px 0px 32px 0px !important;
    }

    // Carousel Text
    .bici-texto {
      margin: 0px 0px 32px 0px !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 350px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
