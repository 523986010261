import React, { useState } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { GrInstagram } from "react-icons/gr";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
// Utils
import useWindowDimensions from "../../../utils/useWindowDimensions";

export default function Redes() {
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      {/* Social Media y Logos Container */}
      <div className="social-media-logos-container">
        {/* Social Media Container */}
        <div className="social-media-container">
          {/* Facebook Button */}
          <a href="https://es-la.facebook.com/SeguroBici/" target="_blank">
            <FaFacebookF />
          </a>
          {/* Instagram Button */}
          <a href="https://www.instagram.com/segurobici/?hl=es" target="_blank">
            <GrInstagram />
          </a>
          {/* Linkedin Button */}
          <a href="https://www.facebook.com/SeguroWeb/" target="_blank">
            <FaLinkedinIn />
          </a>
        </div>
        {/* Logos Container */}
        <div className="logos-container">
          {/* Logo */}
          {width <= 525 ? (
            <></>
          ) : (
            <img
              alt="Logo de Let's Encrypt"
              src="/images/logos/otros/letsencrypt.png"
              id="logo-encrypt"
            ></img>
          )}
          {/* Logo */}
          <a
            href="https://www.argentina.gob.ar/aaip/datospersonales"
            target="_blank"
            id="logo-PDP"
          >
            <img alt="Logo PDP" src="/images/logos/otros/pdp.png" id="logoPDP"></img>
          </a>
          {/* Logo */}
          <a
            href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=vfDql9TuSM09b_FRxAacFg,,"
            target="_F960AFIPInfo"
            id="logo-fiscal"
          >
            <img
              alt="Logo de Data Fiscal"
              src="/images/logos/otros/datafiscal.webp"
            ></img>
          </a>
        </div>
      </div>
    </Container>
  );
}
