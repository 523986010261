import * as React from "react";
import PasoUno from "./paso-uno/index";
import PasoDos from "./paso-dos/index";

export default class ReferidosSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paso: 1,
      historial: null,
      n_siniestro: null
    };
  }

  pasoAnterior = () => {
    const { paso } = this.state;
    this.setState({ paso: paso - 1 });
  };

  pasoSiguiente = (data) => {
    const { paso } = this.state;
    if(paso === 1) this.setState({historial: data.historial, n_siniestro:data.n_siniestro})
    this.setState({ paso: paso + 1 });
  };

  render() {
    const { paso } = this.state;
    switch (paso) {
      case 1:
        return (
          <PasoUno
            handleClose={this.props.handleClose}
            handleScroll={this.props.handleScroll}
            dni={this.props.dni}
            pasoSiguiente={this.pasoSiguiente}
          ></PasoUno>
        );
      case 2:
        return (
          <PasoDos
            handleClose={this.props.handleClose}
            handleScroll={this.props.handleScroll}
            pasoAnterior={this.props.pasoAnterior}
            historial={this.state.historial}
            n_siniestro={this.state.n_siniestro}
          ></PasoDos>
        );
      default:
      // No hace nada
    }
  }
}
