import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { BsWhatsapp } from "react-icons/bs";

export const faqs = [
    {
        id: 1,
        pregunta: "¿Cuáles son los requisitos para asegurar mi bici?",
        respuesta: <p itemprop="text">Foto de la bicicleta (donde se vea una hoja que indique la fecha del día en que se está tomando la foto), factura de compra o presupuesto y la solicitud del seguro{" "} <Link to="header" smooth={true} spy={true} duration={700}>(la podés hacer online)</Link>.</p>
    },
    {
        id: 2,
        pregunta: "¿Puedo contratar sin la factura?",
        respuesta: <p itemprop="text">Sí, podés conseguir un presupuesto en una bicicletería, o SeguroBici puede conseguirlo por vos</p>
    },
    {
        id: 3,
        pregunta: "¿Cuándo empieza a regir mi póliza?",
        respuesta: <p itemprop="text">Desde las 12 hs del día siguiente una vez contratado.</p>
    },
    {
        id: 4,
        pregunta: "¿Hay un tiempo mínimo de permanencia?",
        respuesta: <p itemprop="text">No, podés seguir o dar de baja el seguro cuando desees.</p>
    },
    {
        id: 5,
        pregunta: "¿Cuales son las formas de pago?",
        respuesta: <p itemprop="text">Tarjeta de Credito Bancarizada y CBU.</p>
    },
    {
        id: 6,
        pregunta: "La uso para Delivery, ¿la puedo asegurar?",
        respuesta: <p itemprop="text">Si, tenemos cobertura para Delivery</p>
    },
    {
        id: 7,
        pregunta: "¿Qué me piden en caso de un siniestro?",
        respuesta: <p itemprop="text">Contactarse con Seguro Bici y hacer la denuncia policial. SeguroBici acompañará al ciclista en todo el proceso del Siniestro.</p>
    },
    {
        id: 8,
        pregunta: "¿Dónde llamar en caso de siniestro?",
        respuesta: <p itemprop="text">Contactanos al 0810-220-2373.{" "}
        <Tippy content="De Lun. a Vie. de 9 a 18hs" animation="scale">
          <a
            href="https://api.whatsapp.com/send?phone=541128061540&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20Seguro%20de%20Bici!%20"
            target="_blank"
          >
            <span>
              <BsWhatsapp className="logoMobile" />
            </span>
            11 2806 1540
          </a>
        </Tippy>{" "}
        ,o mandános un mail a:{" "}
        <a id="mailButton" href="mailto:siniestros@seguroweb.com.ar">
          siniestros@seguroweb.com.ar
        </a></p>
    },
    {
        id: 9,
        pregunta: "¿Cuál es la diferencia entre Robo y Hurto?",
        respuesta: <p itemprop="text">Robo: Apoderamiento ilegítimo del bien con el uso de la
        violencia o la intimidación de las personas.
        <br></br>
        <br></br>
        Hurto: Apoderamiento ilegítimo del bien sin uso de la
        violencia o la intimidación de las personas</p>
    },
];
  