import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  /* Título Container Container */
  .titulo-container {
    width: 100%;
    max-width: 1200px;
    margin: 45px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  /* Título Container Text */
  .titulo-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  /* Título Container Span */
  .titulo-container span {
    margin: 0px 7px 0px 0px;
    font-size: 25px !important;
    color: var(--orange);
  }

  // Título Container Text 2
  .titulo-container p:nth-child(2) {
    margin: 20px 0px 0px 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }

  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--white);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--orange);
  }

  // Botón Atras Hover Iconok
  .boton-atras-container button:hover > span {
    color: var(--orange);
    transform: translate(-10px, 0);
  }

  // Dropdowns Container
  .dropdowns-container {
    width: 100%;
    max-width: 1200px;
    margin: 45px 0px 15px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Dropdown Container
  .dropdown-container {
    width: 100%;
    max-width: calc(700px - 30px);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--white);
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.1);
    transition: 0.2s all;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 900px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Botón Atras Container
    .boton-atras-container {
      display: none;
    }

    // Dropdowns Container
    .dropdowns-container {
      width: 100%;
    }

    // Dropdown Container
    .dropdown-container {
      width: fit-content;
    }
  }
`;

export default Container;
