import axios from "axios"
import url from "../utils/url";

export const getATM = async (data) => {
    return await axios.post(`${url}/api/cotizaciones/atm`, data);
}

export const getRUS = async (data) => {
    return await axios.post(`${url}/api/cotizaciones/rus`, data);
}

export const actualizarValoresCotizacion = async (id_operacion, planes) => {
    return await axios.put(`${url}/api/cotizaciones/actualizarValores`, { id_operacion: id_operacion, planes: planes })
}

export const obtenerCotizacion = async (id_operacion) => {
    //return await axios.get(`${url}/api/cotizaciones/${id_operacion}`)
}

export const enviarMailCotizacion = async (id_operacion) => {
    return await axios.post(`${url}/api/sendEmails/cotizacion/${id_operacion}`)
}
