import React, { useEffect } from "react";
import Header from "../components/header";
import Titulo from "../components/institucional/titulo";
import Footer from "../components/footer";
import styled from "styled-components";
import Headroom from "react-headroom";
import Texto from "../components/institucional/texto";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
`;

export default function InstitucionalContainer({ pagina, titulo, tituloSpan }) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        {pagina == "sobre-nosotros" ? (
          <link
            rel="canonical"
            href="https://segurobici.com.ar/sobre-nosotros"
          />
        ) : (
          <link
            rel="canonical"
            href="https://segurobici.com.ar/politicas-y-terminos"
          />
        )}
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <Titulo pagina={pagina} titulo={titulo} tituloSpan={tituloSpan}></Titulo>
      {/* Texto */}
      <Texto pagina={pagina}></Texto>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
}
