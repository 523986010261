import ReactGa from "react-ga";

export const stepForwardMarketing = async (step) => {
    console.log(step)
    ReactGa.event({
        category: `Adelante paso ${step + 1}`,
        action: "Click",
    });
}

export const stepBackwardsMarketing = async (step) => {
    console.log(step)
    ReactGa.event({
        category: `Atrás paso ${step}`,
        action: "Click",
    });
}