export const SOBRE_NOSOTROS = [
  {
    id: 1,
    pregunta: "¿Quiénes somos?",
    respuesta: (
      <p>
        SeguroBici es una marca registrada de la compañía Gestión y Servicios
        S.R.L., bróker de seguros multiproductos que se especializa en el
        asesoramiento y venta de seguros por internet.
        <br></br>Desde 1988, 28 años de experiencia respaldan nuestro
        rendimiento en el mercado de seguros. SeguroBici comercializa seguros
        online por medio de un cotizador que permite comparar compañías y
        coberturas.
      </p>
    ),
    name: "quienesSomos",
  },
  {
    id: 2,
    pregunta: "Nuestros objetivos",
    respuesta: (
      <p>
        Mantener al cliente satisfecho a través de la mejor calidad de servicio,
        en cotización y contratación online de seguros, gestión de siniestros y
        atención personalizada.
        <br />
        ¿Cómo lo hacemos? Contamos con la experiencia y la eficiencia como
        pilares fundamentales, conjuntamente con un importante avance
        tecnológico, lo que avala la propuesta para que usted y sus clientes
        puedan sentirse plenamente respaldados.
      </p>
    ),
    name: "nuestrosObjetivos",
  },
  {
    id: 3,
    pregunta: "Visión comercial",
    respuesta: (
      <p>
        Vivimos en un mundo en permanente cambio y SeguroBici es la demostración
        de esto para nosotros. En un mercado tradicional como lo es el
        asegurador, el avance de la tecnología y las modificaciones del
        comportamiento de los consumidores han posibilitado la llegada de la
        venta de seguros en plataformas web.
        <br />
        SeguroBici, pionero en la venta de seguros por internet, se posiciona
        hoy como líder de este canal, permitiendo constantemente la realización
        de nuevos seguros.
      </p>
    ),
    name: "visionComercial",
  },
  {
    id: 4,
    pregunta: "¿Qué hemos logrado?",
    respuesta: (
      <p>
        Contamos con más de 32.000 pólizas vigentes.
        <br />
        Trabajamos con 23 compañías de seguros, incluyendo a las más importantes
        del país como Federación Patronal, ATM, Libra Seguros, Prudencia Seguros
        y Mapfre, entre muchísimas otras.
        <br />
        Hemos logrado desarrollar una gran comunidad por medio de nuestras redes
        sociales, como así también por nuestro blog y campañas de email
        marketing con más de 40.000 participantes.
      </p>
    ),
    name: "queHemosLogrado",
  },
  {
    id: 5,
    pregunta: "Marca líder",
    respuesta: (
      <p>
        Somos una marca líder y pionera en seguros online, tenemos una vasta
        experiencia para satisfacer todas las expectativas que pueda tener.
        <br />
        Contamos con un departamento de atención al cliente para atender
        siniestros con el objeto de facilitarle todo el asesoramiento que
        necesite y acompañarlo en cuanta ocasión lo requiera, para acelerar
        cualquier trámite y que además reciba un servicio excelente de parte
        nuestra y de la compañia aseguradora.
      </p>
    ),
    name: "marcaLider",
  },
  {
    id: 6,
    pregunta: "Blog y redes sociales",
    respuesta: (
      <p>
        Nuestro interés va más allá de reaccionar y servirle ante un siniestro
        (que es muy importante), pero a nosotros nos preocupa prioritariamente
        su seguridad.
        <br />
        También ponemos a disposición nuestra comunidad en las distintas redes
        sociales, como Facebook, Twitter, Youtube y LinkedIn con beneficios
        especiales, únicos y para participar, interactuar y divertirse en
        nuestras fan pages.
      </p>
    ),
    name: "blogyRedes",
  },
  {
    id: 7,
    pregunta: "Servicio de excelencia",
    respuesta: (
      <p>
        Tenemos la convicción de que le brindaremos un servicio de excelencia.
        <br />
        SeguroWeb es la forma más fácil y rápida de cotizar un seguro de
        cualquier producto que necesites, comparar precios, coberturas y
        compañías, elegir y ahorrar en tus seguros.
      </p>
    ),
    name: "servicioDeExcelencia",
  },
  {
    id: 8,
    pregunta: "Siempre a tu disposición",
    respuesta: (
      <p>
        Somos una empresa líder en la venta de seguros por internet, donde no
        sólo facilitamos la cotización y contratación de las coberturas
        deseadas, sino que también estamos siempre a tu disposición, con
        atención telefónica de lunes a viernes de 9 a 20 hs. o de forma digital,
        ya sea por nuestras redes sociales, web o email.
      </p>
    ),
    name: "disposicion",
  },
  {
    id: 9,
    pregunta: "Nuestros valores",
    respuesta: (
      <p>
        Los valores que nos caracterizan y que cada integrante de nuestra
        empresa se compromete a llevar adelante desde el momento de su ingreso
        son:
        <br />
        ▸ Velar siempre por brindar la mejor calidad de servicio.
        <br />
        ▸ Buscar todos los medios para nunca decirles “no” a nuestros clientes.
        <br />
        ▸ Garantizar plena satisfacción a nuestros clientes. Trabajo en equipo e
        iniciativas individuales como motor para cumplir siempre en tiempo y
        forma.
        <br />
        ▸ La honestidad como base de nuestras virtudes.
        <br />
        ▸ Compromiso, responsabilidad y soluciones.
        <br />
        ▸ Mejora continua, tanto de nuestro servicio como de nuestros producos.
        <br />
        ▸ Adaptación a los cambios del entorno, costumbres y exigencias de los
        clientes, para estar siempre posicionados en un lugar de excelencia y
        satisfacción a los intereses sociales.
        <br />▸ Cultura ecológica, de respeto y contribución al medio ambiente.
      </p>
    ),
    name: "valores",
  },
];
