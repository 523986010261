import { useState } from "react";
import useValidation from "./useValidation";
import { fieldsExcludedFromStorage } from "../constants/form";

export const useForm = (submitCallback, rules, initialValues, extraData) => {
  const [inputValues, setInputValues] = useState(initialValues);
  const { validateForm, formErrors } = useValidation(rules(inputValues, extraData));

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValues((prev) => ({ ...prev, [name]: value }))
    if(!fieldsExcludedFromStorage.includes(name)){
      localStorage.setItem([name] + "-segurobici", value)
    }
  };

  // Para elementos del form que no poseen atributo name (captcha)
  const handleChangeExternal = (fieldName, fieldValue) => {
    setInputValues((prev) => ({ ...prev, [fieldName]: fieldValue }));
  }

  // Validar Formulario
  const handleValidation = (e) => {
    e.preventDefault();
    const formValidation = validateForm();
    console.log(formValidation)
    if (Object.keys(formValidation).length === 0) 
      submitCallback()
  };

  return {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal
  };
};
