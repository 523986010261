import Container from "./styled";
import InputBasic from "../../InputBasic";

const DatosPersonalesForm = ({ formValues, inputsRef }) => {

  const { inputValues, handleChange, formErrors } = formValues
  
  return (
    <Container>
      {/* Input Nombre */}
      <InputBasic 
        label="Nombre"
        type="text"
        id="input-nombre"
        name="nombre"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.nombre}
        className={formErrors.nombre == null ? "" : "input-error"}
        placeholder="Nombre"
        ref={(element) => (inputsRef.current["nombre"] = element)}
      />
      {/* Input Apellido */}
      <InputBasic 
        label="Apellido"
        type="text"
        id="input-apellido"
        name="apellido"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.apellido}
        className={formErrors.apellido == null ? "" : "input-error"}
        placeholder="Apellido"
        ref={(element) => (inputsRef.current["apellido"] = element)}
      />
      {/* Input CUIT o DNI */}
      <InputBasic 
        label="DNI o CUIT"
        type="number"
        id="input-cuit-dni"
        name="cuitDNI"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.cuitDNI}
        className={formErrors.cuitDNI == null ? "" : "input-error"}
        placeholder="DNI o CUIT"
        ref={(element) => (inputsRef.current["cuitDNI"] = element)}
      />
      {/* Input Código Postal */}
      <InputBasic 
        label="Cód. postal"
        type="number"
        id="input-codigo-postal"
        name="codigo_postal"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.codigo_postal}
        className={formErrors.codigo_postal == null ? "" : "input-error"}
        placeholder="Cód. postal"
        ref={(element) => (inputsRef.current["codigo_postal"] = element)}
      />
      {/* Input Email */}
      <InputBasic 
        label="Email"
        type="email"
        id="input-email"
        name="email"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.email}
        className={formErrors.email == null ? "" : "input-error"}
        placeholder="Email"
        ref={(element) => (inputsRef.current["email"] = element)}
      />
      {/* Input Cód. Área */}
      <InputBasic 
        label="C. Área"
        type="number"
        id="input-codigo-area"
        name="codigo_area"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.codigo_area}
        className={formErrors.codigo_area == null ? "" : "input-error"}
        placeholder="C. Área"
        ref={(element) => (inputsRef.current["codigo_area"] = element)}
      />
      {/* Input Teléfono */}
      <InputBasic 
        label="Teléfono"
        type="number"
        id="input-telefono"
        name="telefono"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.telefono}
        className={formErrors.telefono == null ? "" : "input-error"}
        placeholder="Teléfono"
        ref={(element) => (inputsRef.current["telefono"] = element)}
      />
    </Container>
  );
};

export default DatosPersonalesForm;
