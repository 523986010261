import React from 'react'
import Container from './styled'

const DataContainer = ({ children }) => {
  return (
    <Container>
        <div id="formulario-container" className="opacidad05">
            {children}
        </div>
    </Container>
  )
}

export default DataContainer