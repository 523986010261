import { Collapse } from "react-collapse";
import Container from "./styled";

export default function Pregunta ({ title, clickFunction, isActive, children }) {
    return (
        <Container>
          <button
            className={isActive ? "header-abierto" : "header-cerrado"}
            onClick={clickFunction}
            itemscope
            itemprop="mainEntity"
            itemtype="https://schema.org/Question"
          >
            <h4 itemprop="name">{title}</h4>
            <img
              id="buttonIcon"
              alt={isActive ? "Cerrar" : "Abrir"}
              src="/images/home/faqs/arrow.svg"
            ></img>
          </button>
          <Collapse isOpened={isActive}>
            <div
              className="texto"
              itemscope
              itemprop="acceptedAnswer"
              itemtype="https://schema.org/Answer"
            >
              {children}
            </div>
          </Collapse>
        </Container>
      );
}