import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animacion.json";
import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin-top: 0px;
  pointer-events: none;

  div {
    height: 150px !important;
    width: 150px !important;
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    div {
      height: 400px !important;
      width: 400px !important;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    div {
      height: 250px !important;
      width: 250px !important;
    }
  }
`;

export default class AnimacionCarga extends Component {
  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      renderer: "canvas",
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container className="opacidad04">
        <Lottie options={animationOptions} height={100} width={250} />
      </Container>
    );
  }
}
