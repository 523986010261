import Container from "./styled";
import Pregunta from "./pregunta";
import { Fade } from "react-awesome-reveal";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
// Custom Hooks
import { useQuestion } from "../../../hooks/useQuestion";
// FAQs
import { faqs } from "../../../constants/faqs";

export default function Faqs() {
  const { isQuestionOpen, handleToggle } = useQuestion();

  return (
    <Container>
      <html itemscope itemtype="https://schema.org/FAQPage">
        {/* Section Max Width Container */}
        <section id="max-width-container">
          {/* Tag Container */}
          <Fade delay={100} duration={800} className="tag-container">
            {/* Tag */}
            <div>
              <p>FAQs</p>
            </div>
          </Fade>
          {/* Title Container */}
          <Fade delay={100} duration={800} className="title-container">
            {/* Title */}
            <h3>
              <span>Preguntas</span> más frecuentes
            </h3>
          </Fade>
          {/* Preguntas Container */}
          <div id="preguntas-container">
            {faqs.map((faq, index) => {
              return (
                <Fade
                  key={faq.id}
                  delay={100}
                  duration={600}
                  className={
                    isQuestionOpen === index
                      ? "pregunta-container-abierta"
                      : "pregunta-container"
                  }
                >
                  <Pregunta
                    title={faq.pregunta}
                    clickFunction={() => handleToggle(index)}
                    isActive={isQuestionOpen === index}
                  >
                    {faq.respuesta}
                  </Pregunta>
                </Fade>
              );
            })}
          </div>
        </section>
      </html>
    </Container>
  );
}
