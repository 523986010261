import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    max-width: 1270px;
    margin: 0px 0px -32px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 98;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    background-color: var(--blanco);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
    cursor: pointer;

  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--lila90);
  }

  // Botón Atras Hover Iconok
  .boton-atras-container button:hover > span {
    color: var(--lila90);
    transform: translate(-10px, 0);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 40px 0px 0px 0px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 380px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }
  }
`;

export default Container;
