import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100% !important;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Max Width Container
  #max-width-container {
    width: 1270px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  // Texto Container
  #texto-container {
    width: 700px;
    margin: 0px 0px 0px 0px;
    padding: 80px 200px 80px 0px;
    border-right: 1px solid var(--grisClaro);
  }

  // Texto Container Titulo
  #texto-container h3 {
    width: 100%;
    margin: 0px 0px 24px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 22px;
  }

  // Texto Container Texto
  #texto-container p {
    width: 100%;
    margin: 0px 0px 64px 0px;
    text-align: left;
    font-size: 15px;
    color: var(--gris);
    line-height: 32px;
  }

  // Texto Container Botones
  #texto-container a {
    color: var(--naranja100);
  }

  // Texto Container Botones Hover
  #texto-container a:hover {
    color: var(--naranja40);
  }

  // Nav Container
  #nav-container {
    width: ${(props) =>
      props.pagina == "sobre-nosotros"
        ? "160px !important;"
        : "240px !important"};
    padding: 130px 0px 145px 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: flex-start;
    z-index: 96;
  }

  // Nav Container Texto
  #nav-container p {
    width: 100%;
    margin: 0px 0px 12px 0px;
    font-size: 13px;
    font-weight: 600;
  }

  // Nav Container Botones
  #nav-container a {
    width: auto;
    margin: 0px 0px 12px 0px;
    font-size: 13px;
    font-weight: 400;
    color: var(--gris);
    transition: 0.1s all;
  }

  // Nav Container Div
  #nav-container div {
    width: 100%;
    margin: 12px 0px 0px 0px;
    padding: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-top: 1px solid var(--grisClaro);
  }

  // Nav Container Botones Hover
  #nav-container a:hover {
    color: var(--naranja100);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Texto Container
    #texto-container {
      width: 630px;
      padding: 80px 64px 80px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Texto Container
    #texto-container {
      width: 100%;
      padding: 80px 0px 80px 0px;
      border: 0;
    }

    // Plazos Container
    #nav-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
