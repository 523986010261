import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--grisClaro);

  // Max Width Container
  section {
    width: 100%;
    max-width: 1270px;
    padding: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Title Container
  .title-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    h3 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      letter-spacing: -0.015em;
      line-height: 143.5%;

      // Span
      span {
        color: var(--naranja100);
      }
    }
  }

  // Cards Container
  #cards-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 80px 0px 0px 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
  }

  // Card Container
  .card-container {
    width: 287px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // Card Data Container
    #card-data-container {
      width: 100%;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      // Icon & Title Container
      #icon-title-container {
        width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        // Card Icon
        img {
          pointer-events: none;
        }

        // Card Title
        h4 {
          padding: 0px 0px 0px 0px;
          margin: 0px 0px 0px 16px;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: -0.015em;
          color: var(--negro);
        }
      }

      // Card Text
      p {
        width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 32px 0px 0px 0px;
        font-size: 14px;
        line-height: 192.7%;
        letter-spacing: -0.015em;
        color: #747474;
      }
    }
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    section {
      max-width: 1000px;
    }

    // Cards Container
    #cards-container {
      grid-gap: 40px;
    }

    // Card Container
    .card-container {
      width: 218px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    section {
      max-width: 680px;
    }

    // Cards Container
    #cards-container {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }

    // Card Container
    .card-container {
      width: 320px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    section {
      max-width: 480px;
    }

    // Title
    .title-container h3 {
      width: 70%;
      line-height: 36px;
    }

    // Card Container
    .card-container {
      width: 200px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    section {
      max-width: 350px;
    }

    // Title
    .title-container h3 {
      width: 100%;
    }

    // Cards Container
    #cards-container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }

    // Card Container
    .card-container {
      width: 100%;

      // Card Data Container
      #card-data-container {
        // Card Text
        p {
          margin: 24px 0px 0px 0px;
        }
      }
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Container
    section {
      max-width: 85%;
    }
  }
`;

export default Container;
