import React, { useState } from "react";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import Headroom from "react-headroom";
import TitleSection from "../../components/TitleSection";
import Container from "./styled";
import InnerContainer from "../../components/InnerContainer";
import Section from "../../components/Section";
import { useForm } from "../../hooks/useForm";
import { referidosReglas } from "../../utils/formRules";
import { referidosForm } from "../../utils/formInitialValues";
import ReferidosForm from "../../components/Forms/ReferidosForm";
import Aside from "../../components/Aside";
import { Link } from "react-router-dom";
import { goToTop } from "../../utils/scrollToTop";
import Animacion from "../../components/contacto/formulario/confirmacion/animacion";
import DataContainer from "../../components/DataContainer";
import { BsWhatsapp } from "react-icons/bs";
import MessageReceived from "../../components/MessageReceived";

const Referidos = () => {
  const [messageSent, setMessageSent] = useState(false);

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal,
  } = useForm(handleSubmit, referidosReglas, referidosForm);

  console.log(formErrors);

  async function handleSubmit() {
    try {
      //await sendFormMail(inputValues, pasoSiguiente, contactoMail);
      //pasoSiguiente()
      setMessageSent(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar/contacto" />
      </head>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <TitleSection
        tituloSpan="Referidos"
        titulo=""
        tag="Promo"
        descripcion="¡Referí a tus amigos!"
      />
      {/* Formulario */}
      <InnerContainer>
        <DataContainer>
          {!messageSent ? (
            <>
              <h3>¡Aprovechá nuestro programa de referidos!</h3>
              <p id="completa">
                ¿Sos cliente?:
                <br />
                ¡Referí y gana $4000! Contactate a nuestro{" "}
                <a href="https://api.whatsapp.com/send?phone=5491162255399&text=Hola%20%F0%9F%98%80%2C%20soy%20cliente%20y%20quiero%20referir!" target="_blank">
                  <span>
                    <BsWhatsapp />
                  </span>{" "}
                  WhatsApp
                </a>{" "}
                de referidos.
                <br />
                <br />
                ¿No sos cliente?:
                <br />
                ¡Tenés un 20% OFF en el seguro que quieras! Dejanos tu email
                para obtener este beneficio.
              </p>
              <ReferidosForm
                formValues={{
                  inputValues,
                  handleChange,
                  handleChangeExternal,
                  handleValidation,
                  formErrors,
                }}
              />
            </>
          ) : (
              <MessageReceived title="¡Recibimos tu referido!" description={"¡Muchas gracias! Pronto nos pondremos en contacto con vos y tu referido." }/>
          )}
        </DataContainer>
        <Aside>
          <p>Ver más</p>
          <Link to="/contacto">Contacto</Link>
          <Link to="/sobre-nosotros">Sobre nosotros</Link>
          <Link to="/politicas-y-terminos">Políticas y términos</Link>
          <Link to="/forma-parte">Forma parte</Link>
        </Aside>
      </InnerContainer>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
};

export default Referidos;
