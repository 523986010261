import React from "react";
import Container from "./styled";
import { IoClose } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import { FaQuestion } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import disableScroll from "disable-scroll";
import axios from "axios";
import url from "../../../../../utils/url"

export default class pasoUno extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codigoSeguimiento: "",
      formErrors: {},
      message: null
    };
  }

  handleInputChange = (e) => {
    const { name, value, focus } = e.target;
    this.setState({ [focus]: name });
    this.setState({ [name]: value });
  };

  validateForm = (camposRequeridos, modelo)=>{
    let errores = {};
    camposRequeridos.forEach(req => {
        if(modelo[req] === ''){
            errores = {...errores, [req] :true}
        }
    })

    this.setState({formErrors: errores});

    if(Object.keys(errores).length === 0){
        return true;
    }else{
        return false;
    }
}

  searchSiniestro = async (e) =>{
    e.preventDefault();
    this.setState({message:""})

    if(this.validateForm(["codigoSeguimiento"],this.state)){
      try {
        const res = await axios.get(`${url}/api/siniestros/detalle?dni=${this.props.dni}&n_siniestro=${this.state.codigoSeguimiento}`)
        if(res.data.length !== 0){
          this.props.pasoSiguiente({historial: res.data, n_siniestro:this.state.codigoSeguimiento})
        }
        else{
          this.setState({message:"No existen registros con el numero de seguimiento ingresado"})
        }
      } catch (error) {
        console.log(error)
        this.setState({message:"Ocurrió un error al ingresar la consulta, ingrese nuevamente en unos minutos"})
      }
    }
  }
  render() {
    // Disable scroll on
    this.props.toggleScroll != "" && disableScroll.on();
    return (
      <Container>
        <div className="box">
          <div className="closeButtonContainer">
            <a onClick={this.props.handleClose}>
              <IoClose />
            </a>
          </div>
          {/* Título */}
          <h3>Consultá tu denuncia</h3>
          {/* Subtitulo */}
          <div className="ingresar-codigo-container">
            <p>
              <span>
                <RiLockPasswordLine></RiLockPasswordLine>
              </span>
              Ingresá el código de seguimiento
            </p>
            <p>
              El código de seguimiento fue otorgado al finalizar la denuncia
              correspondiente.
            </p>
          </div>
          {/* Input Código de seguimiento */}
          <div className="input-container">
            <input
              type="number"
              id="input-codigo-seguimiento"
              name="codigoSeguimiento"
              onChange={this.handleInputChange}
              onFocus={this.handleInputChange}
              onBlur={this.handleInputChange}
              value={this.state.codigoSeguimiento}
              className={
                this.state.formErrors.codigoSeguimiento == null
                  ? ""
                  : "input-error"
              }
            ></input>
            <label
              className={
                this.state.codigoSeguimiento == null ||
                this.state.codigoSeguimiento == ""
                  ? "label-dowmn"
                  : "label-top"
              }
              htmlFor="inputCodigoSeguimiento"
            >
              Código de seguimiento
            </label>
            {this.state.formErrors.codigoSeguimiento && (
              <span>{this.state.formErrors.codigoSeguimiento}</span>
            )}
          </div>
          {/* Olvidaste tu Código Container */}
          <div className="olvidaste-tu-codigo-container">
            <p>¿Olvidaste tu código de seguimiento?</p>
            {/* Whatsapp Button */}
            <Tippy content="De Lun. a Vie. de 9 a 18hs" animation="scale">
              <a
                href="https://api.whatsapp.com/send?phone=541128061469&text=¡Hola! No recuerdo el código de seguimiento de mi denuncia"
                target="_blank"
              >
                <span>
                  <BsWhatsapp />
                </span>
                Contactanos
              </a>
            </Tippy>
          </div>
          {/* Botón Consultar */}
          <button onClick={this.searchSiniestro}>
            <span>
              <FaQuestion></FaQuestion>
            </span>
            Consultar
          </button>
          <div className="olvidaste-tu-codigo-container">
          <hr/>
          <p>
            {
              this.state.message?
              this.state.message
              :null
            }
            {
              Object.keys(this.state.formErrors).length!==0?
              "Complete los campos requeridos."
              : null
            }
          </p>
        </div>
        </div>
        
      </Container>
    );
  }
}
