import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import Switch from "../components/siniestros/switch";
import Footer from "../components/footer/index";
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--white);
  overflow: hidden;
`;

export default function SiniestrosContainer() {
  return (
    <Container>
      {/* Canonical VivoMarketing */}
      <head>
        <link rel="canonical" href="https://segurobici.com.ar/siniestros" />
      </head>
      {/* Header */}
      <Headroom>
        <Header link={"/"}></Header>
      </Headroom>
      {/* Switch de pasos*/}
      <Switch></Switch>
      {/* Footer */}
      <Footer></Footer>
    </Container>
  );
}
