import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100% !important;
  margin: 65px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid var(--grisClaro);
  background-color: var(--grisClaro2);
  content: "";

  // Max Width Container
  #max-width-container {
    width: 1270px;
    padding: 64px 0px 64px 0px;
    position: relative;
  }

  // Botón Atras
  a {
    width: fit-content;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    color: var(--negro);
    font-size: 13px;
    border: 0px;
    background-color: var(--grisClaro2);
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  a span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  a:hover {
    color: var(--naranja100);
  }

  // Botón Atras Hover Icono
  a:hover > span {
    color: var(--naranja100);
    transform: translate(-10px, 0);
  }

  // Tag y Fecha Container
  #tag-fecha-container {
    width: 100%;
    margin: 16px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Tag
  #tag {
    width: auto;
    margin: 0px 24px 0px 0px;
    padding: 2px 24px 2px 24px;
    background-color: var(--lila90);
    border-radius: 300px;
    position: relative;
    z-index: 95;
  }

  // Tag Texto
  #tag p {
    width: auto;
    margin: 0px 0px 0px 0px;
    font-size: 13px;
    font-weight: 600;
    color: var(--blanco);
    position: relative;
    z-index: 95;
  }

  // Fecha Texto
  #fecha {
    color: var(--gris);
  }

  // Titulo
  h1 {
    margin: 16px 0px 0px 0px;
    position: relative;
    z-index: 95;
  }

  // Titulo Span
  h1 span {
    color: var(--naranja100);
  }

  // Escudo
  #edificios-container {
    width: auto;
    height: 313px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
  }

  #edificios {
    width: 220px;
    margin: 0px 85px 0px 0px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 91;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Escudo
    #edificios-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }

    // Tag y Fecha Container
    #tag-fecha-container {
      margin: 24px 0px 0px 0px;
    }

    // Fecha Texto
    #fecha {
      display: none;
    }
  }
`;

export default Container;
