import { useState } from "react"

export const useSwitch = (stepBackwardsMarketing, stepForwardMarketing) => {
    const [step, setStep] = useState(1)

    const prevStep = () => {
        setStep(prevStep => prevStep - 1)

        if(stepBackwardsMarketing){
            stepBackwardsMarketing(step)
        }
    }
    
    const nextStep = (stepNumber) => {
        if(!stepNumber){
            setStep(step => step + 1)
        } else {
            setStep(stepNumber)
        }

        if(stepForwardMarketing){
            stepForwardMarketing(step)
        }
    }

    return { 
        step,
        prevStep,
        nextStep
    }
}