import * as React from "react";
import Container from "./styled";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Fade } from "react-awesome-reveal";

export default function CarouselBicicletas() {
  return (
    <Container>
      {/* Carousel Container */}
      <Fade
        delay={200}
        duration={800}
        triggerOnce={true}
        className="fade-container"
      >
        {/* Max Width Container*/}
        <div id="max-width-container">
          <div id="carousel-container">
            <Carousel
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={true}
              infiniteLoop={true}
              swipeable={false}
              interval={3000}
              autoPlay
            >
              <div>
                {/* Imagen 1 */}
                <img src="/images/home/bicisAseguradas/Cannondale.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/cannondale.svg"
                />
              </div>
              <div>
                {/* Imagen 3 */}
                <img src="/images/home/bicisAseguradas/spy.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/spy.webp"
                />
              </div>
              <div>
                {/* Imagen 4 */}
                <img src="/images/home/bicisAseguradas/Philco.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/philco.webp"
                />
              </div>
              <div>
                {/* Imagen 5 */}
                <img src="/images/home/bicisAseguradas/Zenith.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/zenith.webp"
                />
              </div>
              <div>
                {/* Imagen 6 */}
                <img src="/images/home/bicisAseguradas/Bianchi.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/bianchi.webp"
                />
              </div>
              <div>
                {/* Imagen 7 */}
                <img src="/images/home/bicisAseguradas/Merida.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/merida.webp"
                />
              </div>
              <div>
                {/* Imagen 8 */}
                <img src="/images/home/bicisAseguradas/Vairo.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/vairo.webp"
                />
              </div>
              <div>
                {/* Imagen 9 */}
                <img src="/images/home/bicisAseguradas/Topmega.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/topmega.webp"
                />
              </div>
              <div>
                {/* Imagen 10 */}
                <img src="/images/home/bicisAseguradas/Venzo.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/venzo.webp"
                />
              </div>
              <div>
                {/* Imagen 11 */}
                <img src="/images/home/bicisAseguradas/Olmo.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/olmo.webp"
                />
              </div>
              <div>
                {/* Imagen 12 */}
                <img src="/images/home/bicisAseguradas/GT.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/gt.webp"
                />
              </div>
              <div>
                {/* Imagen 13 */}
                <img src="/images/home/bicisAseguradas/Raleigh.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/raleigh.webp"
                />
              </div>
              <div>
                {/* Imagen 14 */}
                <img src="/images/home/bicisAseguradas/Firebird.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/firebird.webp"
                />
              </div>
              <div>
                {/* Imagen 15*/}
                <img src="/images/home/bicisAseguradas/SLP.webp" />
                <img
                  className="bici-logo"
                  src="/images/home/bicisAseguradas/logos/slp.webp"
                />
              </div>
              <div>
                {/* Imagen 16*/}
                <img src="/images/home/bicisAseguradas/Electrica.webp" />
                <p className="bici-texto">Eléctricas</p>
              </div>
              <div>
                {/* Imagen 17*/}
                <img src="/images/home/bicisAseguradas/Plegable.webp" />
                <p className="bici-texto">Plegables</p>
              </div>
              {/* Agregar mas imagenes dentro de un div aca, deben ser de 358x193px*/}
            </Carousel>
          </div>
          {/* Informacion Container */}
          <div id="informacion-container">
            {/* Titulo */}
            <h3>
              Aseguramos <span>todos los modelos</span>, hasta bicis eléctricas
              y plegables.
            </h3>
            {/* Texto */}
            <p>
              Deslizá para ver algunos de los modelos y marcas que aseguramos.
            </p>
            <p>
              ¿Tenés dudas respecto a tu bicicleta? No dudes en contactarnos,
              nosotros te ayudamos.
            </p>
          </div>
        </div>
      </Fade>
    </Container>
  );
}
