import styled from "styled-components";

const Container = styled.div`
  width: 283.5px;
  margin: 30px 22px 0px 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  .input-label {
    font-size: 13px;
    font-family: "DM Sans";
    margin-bottom: 10px;
  }

  // Input File Container
  #input-file-container {
    width: 100%;
    height: 180px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-radius: 10px 10px 0px 0px;

    // Carga Container
    #carga-container {
      width: 100%;
      height: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;

      // Imagen Escudo
      img {
        width: 19px;
        position: absolute;
        top: 22px;
        right: 24px;
        pointer-events: none;
        user-select: none;
      }

      // Label
      label {
        width: 100%;
        height: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: var(--blanco);
        border: 1.7px solid var(--grisClaro);
        border-radius: 10px 10px 0px 0px;
        cursor: pointer;
        transition: 0.2s all;

        :hover {
          background-color: #f6f6f6;
        }

        // Ícono
        span {
          width: 100%;
          margin: 0px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          color: var(--lila90);
          font-size: 24px;
          text-align: center;
        }

        // Texto
        p {
          width: 100%;
          margin: 16px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          font-weight: 500;
          color: var(--gris);
          text-align: center;
          line-height: 13px;
        }

        // Texto formato
        p:nth-child(3) {
          margin: 10px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          font-size: 12px;
          font-weight: 400;
        }
      }

      // Input
      input {
        display: none;
      }
    }
  }

  // Archivo Container
  #archivo-container {
    width: 100%;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border: 1.7px solid var(--grisClaro);
    border-radius: 10px 10px 0px 0px;
    position: relative;

    // Imagen Escudo
    img {
      width: 19px;
      position: absolute;
      top: 22px;
      right: 24px;
      pointer-events: none;
      user-select: none;
    }

    // Ícono
    span {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: var(--lila90);
      font-size: 30px;
      text-align: center;
    }

    // Texto
    p {
      width: 80%;
      margin: 8px 10% 0px 10%;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      font-weight: 500;
      color: var(--gris);
      text-align: center;
      line-height: 19px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    // Botón
    a {
      width: auto;
      margin: 10px 4px 0px 4px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      font-weight: 400;
      color: var(--gris);
      text-align: center;
      line-height: 13px;
      transition: 0.1s all;

      // Hover
      :hover {
        opacity: 0.7;
      }
    }
  }

  // Requisitos Container
  #requisitos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--blanco);
    border: 1.7px solid var(--grisClaro);
    border-radius: 0px 0px 10px 10px;

    // Título
    h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      font-weight: 500;
      color: var(--gris);
      text-align: left;
      line-height: 20px;

      // Ícono
      span {
        margin: 3px 4px 0px 0px;
        color: var(--naranja100);
      }
    }

    // Texto y Botón Ejemplo
    p,
    #boton-ejemplo {
      width: 100%;
      margin: 8px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      font-weight: 400;
      color: var(--gris);
      text-align: left;
      line-height: 20px;

      // Botón
      a {
        margin: 0px 0px 0px 4px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        font-weight: 400;
        color: var(--gris);
        text-align: left;
        line-height: 20px;
        text-decoration: underline;
        transition: 0.2s all;

        :hover {
          opacity: 0.7;
        }
      }
    }

    #boton-ejemplo {
      color: var(--naranja100);
    }

    // Texto Peso
    #texto-peso {
      width: 50% !important;
      margin: 24px 0px 0px 0px !important;
      line-height: 9px !important;
    }

    // Botón Ejemplo
    #boton-ejemplo {
      width: 50% !important;
      margin: 22px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      line-height: 9px;
      transition: 0.2s all;

      // Ícono
      span {
        margin: 0px 4px 0px 0px;
        font-size: 13px;
      }

      // Hover
      :hover {
        opacity: 0.7;
      }
    }
  }

  // Input Error Text
  .error-input {
    font-size: 12px;
    color: var(--rojoError);
    margin-top: 10px;
  }
`;

export default Container;
