import Animacion from "../contacto/formulario/confirmacion/animacion";
import Section from "../Section";

const MessageReceived = ({ title, description }) => {

  return (
    <>
      <Section title={title} description={description} />
      <Animacion></Animacion>
    </>
  );
};

export default MessageReceived;
