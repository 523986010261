import React from "react";
import Emoji from "react-emojis";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import Container from "./styled";

export default function ReferidosHeader({ toggleReferidosHeader }) {
  return (
    <Container>
      <div id="max-width-container">
        <a id="cerrar-boton" onClick={toggleReferidosHeader}>
          <IoClose />
        </a>
        <Link to="/referidos" id="boton-referidos">
        🔥
          ¡Una nueva forma de contratar tu seguro. Cotizá tu bicicleta y contratá de manera online, es fácil, rápido, sin intermediarios y con descuentos exclusivos!
          🔥
        </Link>
      </div>
    </Container>
  );
}
