import Container from "./styled";
import FormSection from "../../FormSection";
import { BiError, BiUser } from "react-icons/bi";
import InputBasic from "../../InputBasic";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Button";

const ReferidosForm = ({ formValues }) => {
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidation,
    formErrors,
  } = formValues;

  return (
    <Container>
      <form>
        <FormSection
          title="Datos Personales"
          description="Contanos de vos"
          icon={<BiUser />}
        />
        {/* Input Nombre */}
        <InputBasic
          label="Email"
          type="email"
          id="input-email"
          name="email"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.email}
          className={formErrors.email == null ? "" : "input-error"}
          placeholder="hola@email.com"
          error={formErrors.email}
        />
        {/* Input Nombre */}
        <InputBasic
          label="DNI"
          id="input-dni"
          name="dni"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.dni}
          className={formErrors.dni == null ? "" : "input-error"}
          placeholder="40955588"
          error={formErrors.dni}
        />

        <FormSection
          title="Datos del referido"
          description="Contanos de vos"
          icon={<BiUser />}
        />

        <InputBasic
          label="Nombre"
          type="text"
          id="input-nombre-referido"
          name="nombreReferido"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.nombreReferido}
          className={formErrors.nombreReferido == null ? "" : "input-error"}
          placeholder="Nombre del referido"
          error={formErrors.nombreReferido}
        />

        <InputBasic
          label="Email"
          type="email"
          id="input-email-referido"
          name="emailReferido"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.emailReferido}
          className={formErrors.emailReferido == null ? "" : "input-error"}
          placeholder="referido@email.com"
          error={formErrors.emailReferido}
        />
        <Button type="submit" onClick={handleValidation}>
          Enviar<span class="material-symbols-outlined">mail</span>
        </Button>
      </form>
    </Container>
  );
};

export default ReferidosForm;
