import * as React from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import Container from "./styled";
import { Collapse } from "react-collapse";
import { Link } from "react-scroll";

export default class Dropdown extends React.Component {
  render() {
    return (
      <Container>
        {/* Dropdown */}
        <button
          className={
            this.props.estaActivo ? "dropdown-abierto" : "dropdown-cerrado"
          }
          onClick={this.props.clickFunction}
        >
          {/* Logo y Plan Container */}
          <div className="logo-plan-container">
            <img alt="Logo Seguro" src={this.props.logo}></img>
            <p>{this.props.seguro}</p>
          </div>
          {/* Checks Container */}
          <section className="checks-container">
            {/* Denuncia Policial */}
            <div>
              <span>
                {this.props.denunciaPolicial == "si" ? (
                  <BsCheckLg />
                ) : (
                  <BsXLg id="check-no" />
                )}
              </span>
              <p>
                Denuncia<br></br>policial
              </p>
            </div>
            {/* DNI */}
            <div>
              <span>
                {this.props.dni == "si" ? (
                  <BsCheckLg />
                ) : (
                  <BsXLg id="check-no" />
                )}
              </span>
              <p>DNI</p>
            </div>
            {/* Constancia de CBU */}
            <div>
              <span>
                {this.props.constanciaCBU == "si" ? (
                  <BsCheckLg />
                ) : (
                  <BsXLg id="check-no" />
                )}
              </span>
              <p>
                Constancia<br></br>de tu CBU
              </p>
            </div>
            {/* Factura de Compra */}
            <div>
              <span>
                {this.props.facturaCompra == "si" ? (
                  <BsCheckLg />
                ) : (
                  <BsXLg id="check-no" />
                )}
              </span>
              <p>
                Factura<br></br>de compra
              </p>
            </div>
            {/* Presupuesto de Reposición */}
            <div>
              <span>
                {this.props.presupuestoReposicion == "si" ? (
                  <BsCheckLg />
                ) : (
                  <BsXLg id="check-no" />
                )}
              </span>
              <p>
                Presupuesto<br></br>de reposición
              </p>
            </div>
          </section>
        </button>
      </Container>
    );
  }
}
