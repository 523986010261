import * as React from "react";
import Container from "./styled";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-scroll";
import { Fade } from "react-awesome-reveal";

export default function Cubrimos({ landingVehiculo }) {
  return (
    <Container>
      {/* Coberturas Container */}
      <Fade
        delay={100}
        duration={600}
        triggerOnce={false}
        className="fade-container"
      >
        <div id="coberturas-container">
          {/* Titulo */}
          <h2>
            ¡Hay <span>muchas coberturas </span>
            {landingVehiculo == "monopatin"
              ? "para vos y tu monopatín!"
              : "para vos y tu bici!"}
          </h2>
          {/* Columna 1 */}
          <div className="columna">
            {/* Tag */}
            <div className="tag">
              <p>
                <span>
                  <BsCheckLg />
                </span>
                Accidentes personales
              </p>
            </div>
            <div className="line-break"></div>
            {/* Tag */}
            <div className="tag">
              <p>
                <span>
                  <BsCheckLg />
                </span>
                Daños totales
              </p>
            </div>
          </div>
          {/* Columna 2 */}
          <div className="columna">
            {/* Tag */}
            <div className="tag">
              <p>
                <span>
                  <BsCheckLg />
                </span>
                Daños a terceros
              </p>
            </div>
            {/* Tag */}
            <div className="tag">
              <p>
                <span>
                  <BsCheckLg />
                </span>
                Robo
              </p>
            </div>
          </div>
          {/* Columna 3 */}
          <div className="columna">
            {/* Tag */}
            <div className="tag">
              <p>
                <span>
                  <BsCheckLg />
                </span>
                Auxilio y remolque
              </p>
            </div>
            {/* Tag */}
            <div className="tag">
              <p>
                <span>
                  <BsCheckLg />
                </span>
                Descuentos en service
              </p>
            </div>
          </div>
          {/* Botón Cotizar */}
          <Link
            to="cotizador"
            smooth={true}
            spy={true}
            duration={300}
            offset={-200}
          >
            ¡Cotizá tu seguro!
          </Link>
        </div>
      </Fade>
    </Container>
  );
}
