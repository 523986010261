import styled from "styled-components";
import { breakpoints } from "../../../../../constants/media";

const Container = styled.form`
  width: 100%;
  max-width: 1200px;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .datos-personales,
  .datos-domicilio,
  .datos-bicicleta,
  .datos-hechos,
  .datos-denuncia,
  .otros-seguros {
    width: 100%;
    margin: 45px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;
  }

  .datos-personales p,
  .datos-domicilio p,
  .datos-bicicleta p,
  .datos-hechos p,
  .datos-denuncia p,
  .otros-seguros-title,
  .firma-digital-container p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  #otros-seguros-title-text {
    width: 685px;
    margin: 0px 20px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
  }

  .datos-personales p span,
  .datos-domicilio p span,
  .datos-bicicleta p span,
  .datos-hechos p span,
  .datos-denuncia p span,
  #otros-seguros-title-text span,
  .firma-digital-container p span {
    margin: 0px 7px 0px 0px;
    font-size: 25px !important;
    color: var(--orange);
  }

  .otros-seguros p span {
    font-size: 22px !important;
  }

  // Input Container
  .input-container {
    width: 278px;
    margin: 30px 22px 0px 0px;
    position: relative;
  }

  #input-denuncia-container {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    position: inherit;
  }

  #input-codigo-postal-container {
    width: 128px;
    margin: 30px 0px 0px 0px;
  }

  #input-numero-direccion-container,
  #input-numero-direccion-hechos-container,
  #input-hora-hecho-container,
  #input-fecha-hecho-container,
  #input-codigo-postal-hechos-container {
    width: 128px;
  }

  // Select Container
  .select-container {
    width: 278px;
    height: 35px;
    margin: 30px 22px 0px 0px !important;
    position: relative;
  }

  // Input, Select, Textarea and Label
  .input-container input,
  .input-container label,
  .select-container select,
  .input-container textarea {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 98 !important;
  }

  // Select Label
  .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  // Inputs, Select and Textarea
  .input-container input,
  .select-container select,
  .input-container textarea {
    width: 100%;
    height: 35px;
    padding: 0px 15px 0px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    color: var(--lightgray);
    border: 1px solid var(--lightgray);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px;
  }

  // Input Denuncia
  #input-denuncia {
    display: none;
  }

  #textarea-hecho-container,
  #textarea-detalles-container {
    width: calc(50% - 22px);
  }

  // Select
  .select-container select {
    cursor: pointer;
  }

  // Textarea
  .input-container textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 165px;
    min-height: 165px;
    max-height: 165px;
    padding: 15px 15px 15px 15px !important;
    outline: 0 !important;
  }

  // Label
  .input-container label,
  .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 15px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    color: var(--lightgray);
    cursor: text;
    z-index: 100;
  }

  // Input, Select and Textarea On Focus Label
  .input-container input:focus + label,
  .select-container select:focus + label,
  .input-container textarea:focus + label {
    top: -0.6em;
    background-color: var(--white);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    font-size: 0.7em;
    color: var(--orange) !important;
    z-index: 99 !important;
  }

  // Inputs, Select and Textarea Focus
  .input-container input:focus,
  .select-container select:focus,
  .input-container textarea:focus {
    border: 1px solid var(--orange);
    outline: none;
    z-index: 99 !important;
  }

  // Inputs Error
  .input-error {
    border: 1px solid red !important;
  }

  // Label Down
  .label-down {
    z-index: 97 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: var(--white);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 10px !important;
    font-weight: 600 !important;
    font-size: 0.7em !important;
    color: var(--lightgray) !important;
    z-index: 99 !important;
  }

  // Input Type Number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Input Type Number Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // Select Arrow
  .select-container i {
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
    width: 7px;
    height: 7px;
    display: block;
    border-left: 3px solid var(--lightgray);
    border-bottom: 3px solid var(--lightgray);
    transform: rotate(-45deg);
    z-index: 99;
    border-radius: 10%;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--black);
  }

  // Botón Denuncia y Botón Archivo
  #denuncia-boton,
  .file-container p {
    width: auto;
    height: 25px;
    max-width: none;
    min-width: none;
    margin: 30px 0px 40px 0px;
    padding: 0px 0px 0px 0px;
    position: inherit;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: var(--orange);
    background-color: var(--white);
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.2s all;
  }

  .file-container p {
    cursor: inherit;
  }

  // Botón Denuncia y File Container Ícono
  #denuncia-boton span,
  .file-container p span {
    margin: 0px 5px 0px 0px;
    font-size: 22px;
    cursor: pointer;
  }

  // Botón Denuncia Hover
  #denuncia-boton:hover {
    color: var(--black);
  }

  // File Container Delete Ícono
  .file-container p span {
    margin: 0px 0px 0px 10px;
    font-size: 19px !important;
    color: var(--black);
  }

  // Firma Digital Container
  .firma-digital-container {
    width: 100%;
  }

  // Firma Digital Canvas
  .firma-digital {
    margin: 25px 0px 0px 0px;
    border-radius: 6px;
    border: 1px solid var(--lightgray);
  }

  // Firma Digital Insutrcciones
  #instrucciones-firma-mobile {
    display: none;
  }

    // Firma Digital Insutrcciones
    #instrucciones-firma-mobile {
    display: none;
    width: 250px;
    margin: 15px 0px 0px 0px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: var(--gray);
    line-height: 20px;
  }

  #instrucciones-firma {
    width: 250px;
    margin: 15px 0px 0px 0px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: var(--gray);
    line-height: 20px;
  }

  // Firma Digital Botones
  .botones-firma-container {
    width: 100%;
    margin: 10px 0px 0px 0px;
  }

  // Firma Digital Botón
  .botones-firma-container a {
    margin: 0px 10px 0px 0px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: var(--black);
    cursor: pointer;
  }

  // Botón Container
  .boton-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // ReCAPTCHA
  .boton-container iframe {
    margin: 0px 25px 0px 0px;
    transform: scale(0.9);
    transform-origin: 0 0;
  }

  // Botón
  .boton-container button {
    padding: 12.5px 15px 12.5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--white);
    background-color: var(--orange);
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Hover
  .boton-container button:hover {
    background-color: var(--lightorange);
  }

  // Botón Container Texto
  .boton-container p {
    width: 350px;
    margin: 0px 0px 0px 25px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: left;
    color: var(--gray);
    line-height: 20px;
  }

    // 1366px Desktop ------------------------------------------------------------------------------------
    @media (max-width: ${breakpoints.desktop1366}) {
    max-width: 900px;

    .datos-hechos {
      width: 100%;
    }

    // Input Container
    .input-container,    
    #input-numero-direccion-container, 
    #input-codigo-postal-container,
    #input-numero-direccion-hechos-container,
  #input-hora-hecho-container,
  #input-fecha-hecho-container,
  #input-codigo-postal-hechos-container {
      width: 209px;
      margin: 30px 16px 0px 0px;
    }

    #input-monto-container {
      margin: 30px 100px 0px 0px;
    }

    // Select Container
    .select-container {
      width: 209px;
      margin: 30px 16px 0px 0px !important;
    }

    #otros-seguros-title-text {
      width: 851px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    max-width: 700px;

    #input-monto-container {
      margin: 30px 200px 0px 0px;
    }

    #otros-seguros-title-text {
      width: 1186px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    max-width: 80%;
    justify-content: center;

    .datos-personales,
    .datos-domicilio,
    .datos-bicicleta,
    .datos-hechos,
    .datos-denuncia,
    .datos-hechos-title,
    .otros-seguros {
      justify-content: center;
    }

    .datos-personales p,
    .datos-domicilio p,
    .datos-bicicleta p,
    .datos-hechos p,
    .datos-denuncia p,
    .otros-seguros-title,
    .firma-digital-container p,
    .datos-hechos-title p,
    .otros-seguros-title {
      width: 100%;
      margin: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
    }

    .input-container,   
    #input-numero-direccion-container, 
    #input-codigo-postal-container,
    #input-numero-direccion-hechos-container,
  #input-hora-hecho-container,
  #input-fecha-hecho-container,
  #input-codigo-postal-hechos-container,
  #input-monto-container {
      width: 250px;
      margin: 30px 11px 0px 11px;
    }

    #input-numero-direccion-container, #input-codigo-postal-container {
      width: 250px;
    }

    #textarea-hecho-container {
      width: 70%;
    }

    // Select Container
    .select-container {
      width: 250px;
      margin: 30px 11px 0px 11px !important;
    }

    .otros-seguros-title {
      flex-wrap: wrap;
    }

    #otros-seguros-title-text {
      width: 100%;
      justify-content: center;
    }

    // Textarea Container
    .textarea-container {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      justify-content: center;
    }

    // Textarea
    .input-container textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    #instrucciones-firma {
      display: none;
    }

    // Firma Digital Insutrcciones
    #instrucciones-firma-mobile {
      width: 90%;
      display: block;
      text-align: center;
    }

    #input-denuncia-container {
      justify-content: center;
    }

    #textarea-hecho-container, #textarea-detalles-container {
      width: 79% !important;
    }

    // Firma Digital Container
    .firma-digital-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    // Firma Digital Botones
    .botones-firma-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
    }

    // Firma Digital Botón
    .botones-firma-container a {
      margin: 10px 5px 0px 5px;
    }

    // Botón Container
    .boton-container {
      justify-content: center;
      flex-wrap: wrap;
    }

    // Botón
    .boton-container button {
      padding: 12.5px 15px 12.5px 15px;
      margin: 15px 0px 15px 0px;
    }

    // ReCAPTCHA
    .boton-container iframe {
      margin: 0px 0px 0px 0px;
      transform: scale(0.9);
      transform-origin: 0 0;
    }

    // Botón Container Texto
    .boton-container p {
      width: 90%;
      margin: 20px 0px 0px 0px;
      text-align: center;
    }
  }
`;

export default Container;
