import valid from "card-validator";

// *** Cotizador *** //
export const cotizadorReglas = (state) => {

    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        provincia: [
            {
                regla: state.provincia === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        vehiculo: [
            {
                regla: state.vehiculo === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        codigoArea: [
            {
                regla: state.codigoArea === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.codigoArea.length < 2 ||
                state.codigoArea.length > 5,
                mensaje: 'El código de área debe tener más de 2 digitos y menos de 5.'
            },
        ],
        codigo_postal: [
            {
                regla: state.codigo_postal === "",
                mensaje: 'Este campo es requerido.'
            }, 
            {
                regla: state.codigo_postal.length <= 3,
                mensaje: 'El código postal debe ser de 4 digitos.'
            },
        ],
    }
};

// *** Arrepentimiento *** //
export const arrepentimientoReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        dni: [
            {
                regla: state.dni === "",
                mensaje: 'El campo DNI es requerido.'
            }
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Baja Servicio *** //
export const bajaServicioReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        dni: [
            {
                regla: state.dni === "",
                mensaje: 'El campo DNI es requerido.'
            }
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Contratá *** //
export const contrataReglas = (state, extraData) => {
    const { compania, tieneAccesorios, tab } = extraData

    const notValidCBUs = ["0143", "0147", "0158", "0165", "0384", "000000"]
    //const tarjetasDebito = ["4517", "4815", "5285"]

    const maxFileSizeInMB = 2;
    const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
    const maxFileSizeInKBVideo = 1024 * 1024 * 5;

    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        codigo_area: [
            {
                regla: state.codigo_area === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.codigo_area.length < 2 ||
                state.codigo_area.length > 5,
                mensaje: 'El código de área debe tener más de 2 digitos y menos de 5.'
            },
        ],
        codigo_postal: [
            {
                regla: state.codigo_postal === "",
                mensaje: 'Este campo es requerido.'
            }, 
            {
                regla: state.codigo_postal.length <= 3,
                mensaje: 'El código postal debe ser de 4 digitos.'
            },
        ],
        marca: [
            {
                regla: state.marca === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        modelo: [
            {
                regla: state.modelo === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        rodado: [
            {
                regla: state.rodado === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        color: [
            {
                regla: state.color === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        material: [
            {
                regla: state.material === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        transmision: [
            {
                regla: state.transmision === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        inputNumeroSerie: [
            {
                regla: state.inputNumeroSerie === "" && compania === "Prudencia Seguros",
                mensaje: 'Este campo es requerido.'
            },
        ],
        tamañoCuadro: [
            {
                regla: state.tamañoCuadro === "" && compania === "Prudencia Seguros",
                mensaje: 'Este campo es requerido.'
            },
        ],
        cuitDNI: [
            {
                regla: state.cuitDNI === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.cuitDNI.length !== 11,
                mensaje: 'El CUIL debe contener 11 dígitos.'
            },
        ],
        formaDePago: [
            {
                regla: state.formaDePago === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        titular: [
            {
                regla: state.titular === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.titular.length < 2,
                mensaje: 'El campo Titular debe contener al menos 2 caracteres'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.titular),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        numeroTarjeta: [
            {
                regla: state.numeroTarjeta === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: (state.numeroTarjeta.startsWith("4517") || state.numeroTarjeta.startsWith("4815") || state.numeroTarjeta.startsWith("5285")) && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El número de tarjeta ingresado corresponde a una tarjeta de debito.'
            },
            {
                regla: !valid.number(state.numeroTarjeta).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El número de tarjeta ingresado no es válido.'
            },
            {
                regla: !valid.number(state.numeroTarjeta).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El número de tarjeta ingresado no es válido.'
            },
        ],
        vencimiento: [
            {
                regla: state.vencimiento === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !valid.expirationDate(state.vencimiento).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'La fecha de vencimiento ingresada no es válida.'
            },
        ],
        dniTitular: [
            {
                regla: state.dniTitular === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.dniTitular.length !== 8 && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El campo DNI solo permite 8 digitos.'
            },
        ],
        bancoEmisor: [
            {
                regla: state.bancoEmisor === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[A-Za-z'\s\u00C0-\u017F]+$/.test(state.bancoEmisor),
                mensaje: 'El Banco emisor ingresado no es válido.'
            },
        ],
        cbu: [
            {
                regla: state.cbu === "" && state.formaDePago === "Transferencia bancaria",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.cbu.length !== 22 && state.formaDePago === "Transferencia bancaria",
                mensaje: `El CBU ingresado debe contener 22 digitos. El valor ingresado tiene ${state.cbu.length} digitos.`
            },
            {
                regla: notValidCBUs.some(cbuNumber => state.cbu.startsWith(cbuNumber)) && state.formaDePago === "Transferencia bancaria",
                mensaje: `CBU no bancarizado.`
            },
        ],
        archivo_bicicleta: [
            {
                regla: state.archivo_bicicleta === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.archivo_bicicleta?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        archivo_num_serie_bicicleta: [
            {
                regla: state.archivo_num_serie_bicicleta === "" && state.archivo_num_serie_bicicleta?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        archivo_factura_bicicleta: [
            {
                regla: state.archivo_factura_bicicleta === "" && tab === "factura",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.archivo_factura_bicicleta?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        linkMercadoLibre: [
            {
                regla: state.linkMercadoLibre === "" && tab === "meli",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.linkMercadoLibre && !state.linkMercadoLibre.startsWith("https://www.mercadolibre.com.ar"),
                mensaje: 'El link no es válido.'
            },
        ],
        archivo_presupuesto_bicicleta: [
            {
                regla: state.archivo_presupuesto_bicicleta === "" && tab === "presupuesto",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.archivo_presupuesto_bicicleta?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        accesorios: [
            {
                regla: tieneAccesorios && !state.accesorios,
                mensaje: 'Este campo es requerido.'
            },
        ],
        archivo_factura_accesorios: [
            {
                regla: tieneAccesorios && !state.archivo_factura_accesorios,
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.archivo_factura_accesorios?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        archivo_frente_dni: [
            {
                regla: state.archivo_frente_dni === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.archivo_frente_dni?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
    }
};

// *** Contacto *** //
export const contactoReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        asunto: [
            {
                regla: state.asunto === "",
                mensaje: 'El campo asunto es requerido.'
            }
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        /*
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
        */
    }
};

// *** Referidos *** //
export const referidosReglas = (state) => {
    return {
        nombreReferido: [
            {
                regla: state.nombreReferido === "",
                mensaje: 'El campo "Nombre" en "Datos del referido" es requerido.'
            },
            {
                regla: state.nombreReferido.length < 2,
                mensaje: 'El campo nombre del referido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombreReferido),
                mensaje: 'El nombre del referido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === '',
                mensaje: 'El campo "Email" en "Datos personales" es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        emailReferido: [
            {
                regla: state.emailReferido === '',
                mensaje: 'El campo "Email" en "Datos del referido" es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.emailReferido),
                mensaje: 'El email referido ingresado no es válido.'
            },
        ]
    }
};

// *** Forma Parte *** //
export const formaParteReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        puesto: [
            {
                regla: state.puesto === "",
                mensaje: 'El campo puesto es requerido.'
            }
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        cv: [
            {
                regla: state.cv === "",
                mensaje: 'Tu CV es requerido.'
            }
        ]
    }
};

// *** Red de Bicicleterias *** //
export const redBicicleteriasReglas = (state) => {
    return {
        nombreBicicleteria: [
            {
                regla: state.nombreBicicleteria === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        nombreyapellido: [
            {
                regla: state.nombreyapellido === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
    }
};

// *** Red de Bicicleterias *** //
export const validarSiniestroReglas = (state) => {
    return {
        dni: [
            {
                regla: state.dni === "",
                mensaje: 'El DNI es requerido.'
            },
        ],
    }
};

// *** Red de Bicicleterias *** //
export const crearSiniestrosReglas = (state) => {

    const maxFileSizeInMB = 2;
    const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;

    let checkFiles = []
    if(state.archivo_extras.length > 0){
        state.archivo_extras.forEach(archivo => {
            if(archivo?.size > maxFileSizeInKB){
                checkFiles.push(archivo.name)
            }
        })
    }

    return {
        email: [
            {
                regla: state.email === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo teléfono es menor a 8 digitos.'
            },
        ],
        codigo_area: [
            {
                regla: state.codigo_area === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.codigo_area.length < 2 ||
                state.codigo_area.length > 5,
                mensaje: 'El código de área debe tener más de 2 digitos y menos de 5.'
            },
        ],
        producto: [
            {
                regla: state.producto === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        tipo: [
            {
                regla: state.tipo === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        provincia: [
            {
                regla: state.provincia === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        localidad: [
            {
                regla: state.localidad === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        fecha: [
            {
                regla: state.fecha === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        hora: [
            {
                regla: state.hora === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        calle: [
            {
                regla: state.calle === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        altura: [
            {
                regla: state.altura === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        descripcion: [
            {
                regla: state.descripcion === "",
                mensaje: 'El campo es requerido.'
            },
        ],
        archivo_foto_dni: [
            {
                regla: state.archivo_foto_dni?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        archivo_denuncia_policial: [
            {
                regla: state.archivo_denuncia_policial?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        archivo_foto_bien_siniestrado: [
            {
                regla: state.archivo_foto_bien_siniestrado?.size > maxFileSizeInKB,
                mensaje: 'El archivo cargado excede el límite de 2mb.'
            },
        ],
        archivo_extras: [
            {
                regla: state.archivo_extras.length && state.archivo_extras.length > 5,
                mensaje: 'Solo se permiten hasta 5 archivos.'            
            },
            {
                regla: checkFiles.length > 0,
                mensaje: `Los siguientes archivos pesan más de 2mb: ${checkFiles.join(", ")}`           
            }
        ]
    }
};