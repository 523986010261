import Container from "./styled";

export default function ProgressBar({ steps, title, step }) {

  console.log(steps)

  return (
    <Container>
      {/* Titulo */}
      <h3>{steps[step - 1].title}</h3>
      {/* Pasos Container */}
      <div>
        <div id="pasos-container">
          {steps.map((st, index) => {
            console.log(step)
            console.log(index)
            let id = null
            if(index < step - 1){
              id = "hecho"
            } else if (index === step - 1){
              id = "haciendo"
            } else {
              id = null
            }
            return (
              <div className="paso" id={id}>
                <span></span>
              </div>
            );
          })}
        </div>
        <div id="texto-container">
          {steps.map((step) => {
            return <p className="texto">{step.titleStep}</p>;
          })}
        </div>
      </div>
    </Container>
  );
}
