import * as React from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import Container from "./styled";
import { Collapse } from "react-collapse";
import { Link } from "react-scroll";

export default class Dropdown extends React.Component {
  render() {
    return (
      <Container>
        {/* Dropdown */}
        <button
          className={
            this.props.estaActivo ? "dropdown-abierto" : "dropdown-cerrado"
          }
          onClick={this.props.clickFunction}
        >
          {/* Logo y Plan Container */}
          <div className="logo-plan-container">
            <img alt="Logo Seguro" src={this.props.logo}></img>
            <p>{this.props.seguro}</p>
          </div>
          {/* Checks Container */}
          <section className="checks-container">
            {/* Denuncia Policial */}
            <span>
              {this.props.denunciaPolicial == "si" ? (
                <BsCheckLg />
              ) : (
                <BsXLg id="check-no" />
              )}
            </span>
            {/* DNI */}
            <span>
              {this.props.dni == "si" ? <BsCheckLg /> : <BsXLg id="check-no" />}
            </span>
            {/* Constancia de CBU */}
            <span>
              {this.props.constanciaCBU == "si" ? (
                <BsCheckLg />
              ) : (
                <BsXLg id="check-no" />
              )}
            </span>
            {/* Factura de Compra */}
            <span>
              {this.props.facturaCompra == "si" ? (
                <BsCheckLg />
              ) : (
                <BsXLg id="check-no" />
              )}
            </span>
            {/* Presupuesto de Reposición */}
            <span>
              {this.props.presupuestoReposicion == "si" ? (
                <BsCheckLg />
              ) : (
                <BsXLg id="check-no" />
              )}
            </span>
          </section>
          {/* Botones Container */}
          <div className="botones-container">
            {/* Botón Contrata */}
            <Link
              id="contrata-boton"
              onClick={(event) => { this.props.pasoSiguiente(); this.props.setSeguro()}}
              to="header"
              spy={true}
            >
              Denunciar
            </Link>
            {/* Botón Ver mas */}
            <button id="ver-mas-boton" onClick={this.props.handleToggle}>
              {this.props.estaActivo ? "Ver menos" : "Ver más"}
            </button>
          </div>
        </button>
        {/* Contenido */}
        <Collapse isOpened={this.props.estaActivo}>
          <div className="children-container">{this.props.children}</div>
        </Collapse>
      </Container>
    );
  }
}
