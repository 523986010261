import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  backdrop-filter: saturate(100%) blur(13px);
  animation-name: opacidad-in;
  animation-duration: 0.2s;
  animation-iteration-count: initial;
  animation-timing-function: ease-out;
  transition: 0.2s all;
  z-index: 98 !important;

  @keyframes opacidad-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Modal Container
  #modal-container {
    width: 500px;
    margin: 0px 0px 0px 0px;
    padding: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
    background-color: var(--blanco);
    border: 1.7px solid var(--grisClaro);
    border-radius: 10px;

    // Botón Cerrar
    a {
      width: auto;
      margin: 0px 24px 0px 0px;
      display: block;
      cursor: pointer;
      font-size: 20px;
      color: var(--negro);
      transition: 0.1s all;

      // Hover
      :hover {
        opacity: 0.7;
      }
    }
  }

  // Imagen Container
  #imagen-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 16px 32px 40px 32px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    border-bottom: 1.7px solid var(--grisClaro);

    // Imagen
    img {
      width: 90%;
      max-height: 246.66px;
      user-select: none;
      pointer-events: none;
    }
  }

  // Texto Container
  #texto-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    // Título
    h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      font-weight: 500;
      color: var(--gris);
      text-align: left;
      line-height: 20px;

      // Ícono
      span {
        margin: 3px 4px 0px 0px;
        color: var(--naranja100);
      }
    }

    // Texto
    p, a {
      width: 100%;
      margin: 8px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      font-weight: 400;
      color: var(--gris);
      text-align: left;
      line-height: 20px;
      display: inline;
    }

    a {
      text-decoration: underline;
    }
  }
`;

export default Container;
