import react, { useState, useEffect } from "react";
import { BsCheckLg, BsArrowLeft } from "react-icons/bs";
import { useScrollDirection } from "react-use-scroll-direction";
import Container from "./styled";
import NumberFormat from "react-number-format";
import { Fade } from "react-awesome-reveal";
import { useBack } from "use-back";
import Plan from "../../Plan";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useQuestion } from "../../../hooks/useQuestion";
// Context
import { useWizardContratacion } from "../../../context/WizardContratacionProvider";
import BackButton from "../../BackButton";

const ElegirPlan = () => {
  
    const { nextStep, planSelected, planes, cotizacion, handlePlan } = useWizardContratacion()

    console.log(planSelected)

    // Custom Hooks
    const { isQuestionOpen, handleToggle } = useQuestion()
    useScrollTop()
  
    // Scroll Direction
    const { isScrollingUp, isScrollingDown } = useScrollDirection();
  
    const [scrollea, setScrollea] = useState(false);
  
    const handleScroll = () => {
      if (isScrollingUp) {
        setScrollea(true);
      } else if (isScrollingDown) {
        setScrollea(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);
  
    useEffect(() => {
      const scriptPixel = document.createElement("script");
      const scriptGoogleAds = document.createElement("script");
  
      scriptPixel.text = `fbq('track', 'Lead', {})`;
      scriptGoogleAds.text = `gtag('event', 'conversion', {'send_to': 'AW-959485109/uy9TCIC7nFcQtanCyQM'})`;
  
      document.body.appendChild(scriptPixel);
      document.head.appendChild(scriptGoogleAds);
  
      return () => {
        document.body.removeChild(scriptPixel);
        document.head.removeChild(scriptGoogleAds);
      };
    }, []);

  return (
    <Container>
      <BackButton text="Volver a cotizar" prevStep="/" />
      {/* Valor Auto */}
      <div className="valor-vehiculo-container">
        {/* Texto */}
        <span>
          {localStorage.getItem("segurobici-vehiculo") == "bicicleta electrica"
            ? "El valor de tu bicicleta eléctrica es de: "
            : localStorage.getItem("segurobici-vehiculo") ==
              "monopatin electrico"
            ? "El valor de tu monopatín eléctrico es de: "
            : "El valor de tu " +
              localStorage.getItem("segurobici-vehiculo") +
              " es de: "}
          <NumberFormat
            // Aca despues hay que cambiarlo por el valor desde localStorage
            value={localStorage.getItem("segurobici-sumaasegurada")}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value, props) => <p {...props}>{value}</p>}
          />
        </span>
      </div>
      {/* Referencias Container */}
      <div className="referencias-container opacidad03">
        {/* Referencias */}
        <div className="referencias">
          <p>Resp. Civil</p>
          <p>Robo Total</p>
          <p>Robo Parcial</p>
          <p>Destr. Total</p>
          <p>Asistencia</p>
          <p>Competencias</p>
          <p id="cuotas-mensuales">Precio</p>
        </div>
      </div>
      <div className="dropdowns-container">
        {/* Render de cada plan */}
        {planes.map((plan, index) => (
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className={
              plan.mejor_precio
                ? "dropdown-mejor-precio"
                : plan.recomendado
                ? "dropdown-recomendado"
                : "dropdown-container"
            }
          >
            <Plan
              data={plan}
              index={index}
              active={isQuestionOpen}
              handleToggle={handleToggle}
              planSelected={handlePlan}
              nextStep={nextStep}
              cotizacion={cotizacion}
              landing={cotizacion.tipo}
            />
          </Fade>
        ))}
        {/* Coberturßa uso laboral  */}
        {localStorage.getItem("segurobici-vehiculo") == "bicicleta" && (
          <Fade
            delay={100}
            duration={600}
            triggerOnce={true}
            className="cobertura-laboral-container"
          >
            <p id="cobertura-laboral">
              <span id="texto-laboral">
                Estas coberturas son únicamente para uso particular.
              </span>{" "}
              <span id="pregunta-laboral">¿Trabajas con tu bici?</span>{" "}
              <a href="/seguro-de-bicicleta-comercial">
                Cotizá una cobertura especial para uso comercial
              </a>
            </p>
          </Fade>
        )}
      </div>
    </Container>
  );
};

export default ElegirPlan;
