import { StickyContainer, Sticky } from "react-sticky";
import { Link } from "react-router-dom";

const Aside = ({ children }) => {
  return (
    <StickyContainer>
      <Sticky>
        {({ style }) => (
             <div id="plazos-container" style={style}>
                {children}
            </div>
        )}
      </Sticky>
    </StickyContainer>
  );
};

export default Aside;
