import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 24px 0px;
  background-color: var(--celeste90);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  // SSN Container
  #ssn-container {
    width: 1140px;
    padding: 32px 0px 32px 0px;
    margin: 0px 0px 0px 0px;
    background-color: var(--celeste90);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    border-top: 2px solid var(--gris90);

    // Data Logo SSN Container
    #data-logo-ssn-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      // Logo SSN Container
      #logo-ssn-container {
        width: auto;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
      }

      // Textos y botones
      p,
      a {
        width: auto;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12.5px;
        line-height: 180%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--gris);
        transition: 0.2s all;
      }

      // Logo SSN
      #logo-SSN {
        height: 29.33px;
        opacity: 1 !important;

        img {
          max-width: 200px;
        }
      }

      // Botones Hover
      a:hover {
        opacity: 0.5;
      }
    }

    // Texto Legal
    #texto-legal {
      width: 58%;
      margin: 28px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }

    // Contacto SSN Container
    #contacto-ssn-container {
      width: calc(42% - 64px);
      margin: 28px 0px 0px 64px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-content: flex-start;
      align-items: flex-start;

      p {
        width: 100%;
        margin: 16px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        text-align: right;
        letter-spacing: -0.015em;
        color: var(--gris);
      }

      p:first-child {
        margin: 0px 0px 0px 0px !important;
      }
    }
  }

  // Copyright Container
  #copyright-container {
    width: 1140px;
    margin: 0px 160px 0px 160px;
    padding: 16px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-top: 2px solid var(--gris90);

    // Texto
    p {
      width: auto;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--gris);
    }

    // SeguroWeb Logo
    a {
      width: auto;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: 26px;
      }
    }
  }

  // 1860px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1860}) {
    // Copyright Container
    #copyright-container {
      margin: 0px 0px 0px 0px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // SSN Container & Copyright Container
    #ssn-container,
    #copyright-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // SSN Container & Copyright Container
    #ssn-container,
    #copyright-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // SSN Container & Copyright Container
    #ssn-container,
    #copyright-container {
      width: 480px;
    }

    #ssn-container {
      // Data Logo SSN Container
      #data-logo-ssn-container {
        // Logo SSN Container
        #logo-ssn-container {
          width: 100%;
          justify-content: flex-start;
        }

        // Logo SSN
        #logo-SSN {
          margin: 0px 0px 24px 0px;
        }
      }

      // Texto Legal
      #texto-legal {
        width: 100%;
      }

      // Contacto SSN Container
      #contacto-ssn-container {
        width: 100%;
        margin: 28px 0px 0px 0px;
        justify-content: flex-start;

        p {
          width: 100%;
          text-align: left;
        }
      }
    }

    // Copyright Container
    #copyright-container {
      justify-content: flex-start;

      // Texto
      p {
        width: 100%;
      }

      // SeguroWeb Logo
      a {
        margin: 24px 0px 0px 0px;
        justify-content: flex-start;
      }
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // SSN Container & Copyright Container
    #ssn-container,
    #copyright-container {
      width: 400px;

      // Data Logo SSN Container
      #data-logo-ssn-container {
        justify-content: flex-start;

        // Textos y botones
        p,
        a {
          margin: 8px 16px 0px 0px;
        }
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // SSN Container & Copyright Container
    #ssn-container,
    #copyright-container {
      max-width: 85%;
    }
  }
`;

export default Container;
